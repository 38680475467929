import dynamic from 'next/dynamic';
import { COUNTRY } from '../../../../data/constants';
import useLang from '../../../../services/language/useLang';
import { useLocalize } from '../../../../services/localize/useLocalize';
import IconFont from '../../../../ui-kit/IconFont';
import { sendHrHeaderClick } from '../../../../utils/enhancedEcommerce';
import Dropdown from '../Dropdown/Dropdown';
import StylishLink from '../../../../ui-kit/StylishLink';
import Stack from '../../../../ui-kit/Stack/Stack';
import StylishBox from '../../../../ui-kit/StylishBox/StylishBox';

const MenuList = dynamic(
  () => import('./MenuList'),
  { ssr: false },
);

const Menu = () => {
  const currentLanguage = useLang();
  const localize = useLocalize();

  const links = [
    {
      label: localize('pages_help'),
      href: `https://help.zakaz.ua/hc/${currentLanguage === 'en' ? 'en-us' : currentLanguage}/`,
    },
    {
      label: localize('pages_contacts'),
      href: `/${currentLanguage}/contacts/`,
    },
  ];

  return (
    <Stack className={`Menu`}
      alignItems="center"
      gap="v6"
      sbs={{ fontSize: '14px' }}
    >
      <Dropdown
        label={localize('for_partners')}
      >
        <StylishBox sbs={{ width: '230px' }}>
          <MenuList />
        </StylishBox>
      </Dropdown>

        {COUNTRY === 'ua' &&
          <StylishLink
            href="https://info.zakaz.ua/hr/"
            target="_blank"
            rel="nofollow noopener noreferrer"
            data-marker="Link Vacancies Header"
            onClick={sendHrHeaderClick}
          >
            {localize('link_vacancies')}
            <IconFont name="arrow-up-right" sbs={{ ml: '4px' }} />
          </StylishLink>
        }

        {links.map(link => {
          return (
            <StylishLink innerLink key={link.label} href={link.href}>
              {link.label}
            </StylishLink>
          );
        })}
    </Stack>
  );
};

export default Menu;
