import isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import { useRouter } from "next/router";
import { RangeFilter } from "src/api/types";
import { FILTER_PRICE_SEPARATOR } from "src/data/constants";
import useUpdateQueryParameter from "./useUpdateQueryParameter";
import getFilterNamesWithCurrency from "../utils/getFilterNamesWithCurrency";
import { getMinMaxQueryFilterValue } from "../utils/getParsedFilterPriceValue";
import useLocalize from "src/services/localize/useLocalize";
import useFilterPrice from "src/ui-kit/FilterPrice/useFilterPrice";

const useFilterPriceWrap = (filter: RangeFilter) => {
  const localize = useLocalize();
  const { query } = useRouter();
  const { updateQueryParameter } = useUpdateQueryParameter();

  const { result, values } = getMinMaxQueryFilterValue(filter, query);

  const {
    getNewRangeValueFromInputs,
    filterPriceProps,
  } = useFilterPrice({
    range: result,
    values,
  });

  const filterName = getFilterNamesWithCurrency(filter.name, localize);

  const { inputsProps } = filterPriceProps;

  const onSubmit = () => {
    const newRangeValue = getNewRangeValueFromInputs();
    const rangeValue = `${newRangeValue[0]}${FILTER_PRICE_SEPARATOR}${newRangeValue[1]}`;

    updateQueryParameter('price', rangeValue);
  };

  const isChanged = !isEqual(values, omit(inputsProps, ['onChange']));

  return {
    filterPriceProps,
    filterName,
    onSubmit,
    isChanged,
  };
};

export default useFilterPriceWrap;
