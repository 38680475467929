import StylishBox from 'src/ui-kit/StylishBox/StylishBox';
import { COUNTRY } from '../../data/constants';
import SocialItem from './SocialItem';
import { SocialsMap } from './types';

const socials: SocialsMap = {
  ua: [
    {
      type: "facebook",
      url: 'https://www.facebook.com/Zakaz.ua',
    },
    {
      type: "instagram",
      url: 'https://www.instagram.com/zakaz.ua/',
    },
    {
      type: "linkedin",
      url: 'https://www.linkedin.com/company/zakaz-ua/',
    },
    {
      type: "tiktok",
      url: 'https://www.tiktok.com/@zakaz.ua',
    },
  ],
};

interface Props {
  dataMarker?: string;
}

const Socials = (props: Props) => {
  const { dataMarker } = props;

  return (
    <StylishBox
      className="Socials"
      dataMarker={dataMarker}
      sbs={{ display: 'flex' }}
    >
      {socials[COUNTRY].map((social, index) => (
        <StylishBox
          className="Socials__item"
          key={index}
          sbs={{ mr: '16px' }}
        >
          <SocialItem {...social} />
        </StylishBox>
      ))}
    </StylishBox>
  );
};

export default Socials;
