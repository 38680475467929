import CheckboxWithLabel, { CheckboxWithLabelProps } from 'src/UIKitOld/CheckboxWithLabel/CheckboxWithLabel';
import Stack from 'src/ui-kit/Stack/Stack';
import { Typography } from 'src/ui-kit/Typography';

export interface CheckboxWithAmountProps extends Omit<CheckboxWithLabelProps, 'children'> {
  text: string;
  amount?: number;
}

function CheckboxWithAmount(props: CheckboxWithAmountProps) {
  const { text, amount, ...rest } = props;

  return (
    <CheckboxWithLabel
      {...rest}
      fullWidth
    >
      <Stack
        sbs={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
        }}
      >
        <Typography variant="text6">
          {text}
        </Typography>
        {
          amount !== undefined && (
            <Typography
              variant="caption1"
              color='gray600'
              dataMarker='Checkbox amount'
              dataTestId='checkout-amount'
            >
              {amount}
            </Typography>
          )
        }
      </Stack>
    </CheckboxWithLabel>
  );
}

export default CheckboxWithAmount;
