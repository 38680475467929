import { KeyboardEventHandler, ReactNode } from 'react';
import SliderField, { SliderFieldProps } from 'src/ui-kit/SliderField/SliderField';
import StylishBox from 'src/ui-kit/StylishBox/StylishBox';
import { useTheme } from 'src/ui-kit/theme';
import Input from 'src/UIKitOld/Input/Input';

export interface InputsProps {
  min: number;
  max: number;
  onChange: (name: "min" | "max", value: string) => void;

}
export interface FilterPriceProps {
  inputsProps: InputsProps;
  sliderFieldProps: SliderFieldProps;
  buttonSlot?: ReactNode;
  onSubmit?: () => void;
}

const FilterPrice = (props: FilterPriceProps) => {
  const { sliderFieldProps, inputsProps, buttonSlot, onSubmit } = props;
  const theme = useTheme();

  const handleEnter: KeyboardEventHandler = (event) => {
    if (event.key === 'Enter' && typeof onSubmit === 'function') {
      onSubmit();
    }
  };

  return (
    <StylishBox dataMarker='Filter Price'
      elementProps={{
        onKeyDown: handleEnter,
      }}
    >
      <StylishBox
        sbs={{
          mx: '8px',
          mb: {
            general: '16px',
            tabletPhone: '35px',
          },
        }}
      >
        <SliderField {...sliderFieldProps } />
      </StylishBox>

      <StylishBox
        sbs={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Input
          dataMarker='Filter Price Min'
          value={String(inputsProps.min / 100)}
          onChange={e => inputsProps.onChange('min', e.target.value)}
          inputMode='numeric'
          testId="price-min"
        />

        <StylishBox
          sbs={{
            mx: '8px',
            width: '10px',
            height: '1px',
            backgroundColor: theme.base.color.dark,
            flexShrink: '0',
          }}
        />

        <Input
          dataMarker='Filter Price Max'
          value={String(inputsProps.max / 100)}
          onChange={e => inputsProps.onChange('max', e.target.value)}
          inputMode='numeric'
          testId="price-max"
        />

        {buttonSlot}
      </StylishBox>
    </StylishBox>
  );
};

export default FilterPrice;
