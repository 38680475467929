import css from 'styled-jsx/css';

const styles = css`
  .ProductsSliderPlaceholder {
    position: relative;
    width: 100%;
  }

  .ProductsSliderPlaceholder__inner {
    background: white;
    border-color: #F2F2F2;
    border-width: 1px 0 1px 1px;
    border-style: solid;
    padding: 8px;
  }

  .ProductsSliderPlaceholder__title {
    margin: 7px 0 5px;
    padding: 0;
    height: 40px;
    text-align: start;
  }

  .ProductsSliderPlaceholder__title:first-letter {
    text-transform: uppercase;
  }

  .ProductsSliderPlaceholder__shape {
    border-radius: 10px;
  }

  .ProductsSliderPlaceholder__image {
    margin-bottom: 10px;
    height: 188px;
  }

  .ProductsSliderPlaceholder__price {
    height: 70px;
  }
`;

export default styles;
