import Image from "next/image";
import Link from "next/link";
import { useMediaQuery } from "react-responsive";
import { defaultProductImage } from "src/fixtures";
import { phone } from "src/styles/media";
import { useTheme } from "src/ui-kit/theme";
import { CategoryCardStyled } from "./CategoryCard.styles";

interface Props {
  imgSrc: string | undefined;
  title: string;
  href: string;
  onClick(): void;
}

const CategoryCard = (props: Props) => {
  const { imgSrc, title, href, onClick } = props;
  const isPhone = useMediaQuery({ query: phone });
  const imgSide = isPhone ? 64: 56;
  const theme = useTheme();

  return (
    <Link href={href} onClick={onClick}>
      <CategoryCardStyled
        theme={theme}
        className="CategoryCard"
      >
        <div className="CategoryCard__title">
          {title}
        </div>
        <div className="CategoryCard__imgWrapper">
          <Image
            src={imgSrc || defaultProductImage}
            alt={title}
            width={imgSide}
            height={imgSide}
          />
        </div>
      </CategoryCardStyled>
    </Link>
  );
};

export default CategoryCard;
