import styled from '@emotion/styled';
import { StyledThemeProps } from 'src/ui-kit/theme/types';

export const BreadcrumbsStyled = styled('div', {
  shouldForwardProp: (prop) => prop !== 'theme',
})<StyledThemeProps>(({ theme }) => `
  .Breadcrumbs__list {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    flex-wrap: wrap;
    padding-bottom: 8px;
    margin-bottom: 24px;
    border-bottom: 1px solid ${theme.base.color.gray300};
  }
  .Breadcrumbs__item {
    font-size: 14px;
    line-height: 21px;
    color: ${theme.base.color.gray600};

    position: relative;
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .Breadcrumbs__link {
    color: ${theme.base.color.dark};
    text-decoration: none;
  }
  .Breadcrumbs__link:hover {
    color: ${theme.button.ghost.colorHover};
  }

  .Breadcrumbs__item--disabled .Breadcrumbs__link {
    cursor: auto;
    color: ${theme.base.color.gray500};
  }

  .Breadcrumbs__item:first-letter {
    text-transform: uppercase;
  }

  .Breadcrumbs__separator {
    margin: 8px;
    font-size: 7px;
    color: ${theme.base.color.gray600};
    transform: rotate(90deg);
    opacity: 0.4;
  }
`);
