import { getEventType } from './getEventType';
import { getSwipeDirection } from './getSwipeDirection';
import { ITouchData, ISwipeParams } from '../types';

const swipeSensitivity = 0.3;

export function getSwipeParams(touchData: ITouchData): ISwipeParams {
  const { touchStart, touchEnd, timestampStart, timestampEnd } = touchData;

  if (touchStart === touchEnd) return {};

  const spacing = touchStart - touchEnd;
  const time = timestampEnd - timestampStart;
  const speed = Math.abs(spacing) / time;

  return {
    swipeDirection: getSwipeDirection(spacing),
    eventType: getEventType(speed, swipeSensitivity),
    duration: time,
  };
}
