import { RectShape, TextBlock } from 'react-placeholder/lib/placeholders';
import { useTheme } from 'src/ui-kit/theme';
import { ProductTilePlaceholderStyled } from './ProductTilePlaceholder.styles';

interface Props {
  repeat: number;
}

const ProductTilePlaceholder = ({ repeat }: Props) => {
  const theme = useTheme();

  const rectColor = theme.base.color.gray100;

  return (
    <ProductTilePlaceholderStyled
      theme={theme}
      className="ProductTilePlaceholder"
    >
      {[...Array(repeat)].map((_, i) => (
        <div key={i} className='ProductTilePlaceholder__item'>
          <div className='ProductTilePlaceholder__image'>
            <RectShape color={rectColor}/>
          </div>
          <div className='ProductTilePlaceholder__button'>
            <RectShape color={rectColor}/>
          </div>
          <div className='ProductTilePlaceholder__details'>
            <TextBlock rows={3} color={rectColor} lineSpacing={'6px'} />
          </div>
        </div>
      ))}
    </ProductTilePlaceholderStyled>
  );
};

export default ProductTilePlaceholder;
