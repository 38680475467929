import { useTheme } from 'src/ui-kit/theme';
import styles from './Spinner.styles';

interface Props {
  size?: string;
  color?: string;
}

const Spinner = (props: Props) => {
  const { size = '37px', color } = props;
  const theme = useTheme();

  return (
    <svg className='Spinner'  data-testid='spinner' width={size} height={size} viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.6077 28.7846C30.3472 25.4709 32.3137 18.1318 29 12.3923C25.6863 6.65279 18.3472 4.68629 12.6077 8C6.86818 11.3137 4.90168 18.6528 8.21539 24.3923"
        stroke={color || theme.base.color.primary}
        strokeWidth="2"
      />
      <style jsx>{styles}</style>
    </svg>
  );
};

export default Spinner;
