import Skeleton from 'src/components/Loaders/Skeleton/Skeleton';
import StylishBox from 'src/ui-kit/StylishBox/StylishBox';
import FlexList from '../../../../components/FlexList/FlexList';

interface RetailsInfoSkeletonProps {
  size: 'large' | 'medium' | 'small';
}

const RetailsInfoSkeleton = (props: RetailsInfoSkeletonProps) => {
  const { size } = props;
  const numberOfItems = size === 'large' ? 15 : (size === 'medium' ? 9 : 5);
  const data = new Array(numberOfItems).fill(null).map((_, i) => ({ id: String(i) }));

  return (
    <div className='RetailsInfoSkeleton'>
      <FlexList
        size={ size }
        data={ data }
        renderItem={ () =>
          <StylishBox
            className='RetailsInfoSkeleton__item'
            sbs={{ height: '70px' }}
          >
            <Skeleton/>
          </StylishBox>
        }
      />
    </div>
  );
};

export default RetailsInfoSkeleton;
