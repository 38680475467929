import IconFont from '../../../../ui-kit/IconFont';
import styles from './CartCountIcon.styles';

interface CartCountIconProps {
  count: number;
}

const CartCountIcon = (props: CartCountIconProps) => {
  const { count } = props;

  return (
    <div className='CartCountIcon' >
      <IconFont name='cart-outlined' color='dark' size='19px' />
      <span
        className="CartCountIcon__count"
        data-marker='Cart Count'
      >
        {count}
      </span>
      <style jsx>{styles}</style>
    </div>
  );
};

export default CartCountIcon;
