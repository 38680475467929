import { RectShape, TextBlock } from 'react-placeholder/lib/placeholders';
import styles from './ProductsSliderPlaceholder.style';

const ProductsSliderPlaceholder = () => {
  return (
    <div className="ProductsSliderPlaceholder">
      <div className="ProductsSliderPlaceholder__inner">
        <div className="ProductsSliderPlaceholder__image">
          <RectShape color="#F8F8F8" className="ProductsSliderPlaceholder__shape" />
        </div>
        <div className="ProductsSliderPlaceholder__price">
          <TextBlock rows={2} color="#eee" />
        </div>
        <TextBlock rows={3} color='#F8F8F8' />
      </div>
      <style jsx>{styles}</style>
    </div>
  );
};

export default ProductsSliderPlaceholder;
