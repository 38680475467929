import { Fragment } from 'react';
import Responsive from 'src/components/Responsive';
import RetailsInfoSkeleton from '../RetailsInfoSkeleton/RetailsInfoSkeleton';

export default function ResponsiveSkeleton() {
  return (
    <Fragment>
      <Responsive displayOn='desktop'>
        <RetailsInfoSkeleton size='large' />
      </Responsive>
      <Responsive displayOn='tablet'>
        <RetailsInfoSkeleton size='medium' />
      </Responsive>
      <Responsive displayOn='phone'>
        <RetailsInfoSkeleton size='small' />
      </Responsive>
    </Fragment>
  );
}
