import Head from 'next/head';

export interface SearchMetaProps {
  title?: string;
}

const SearchMeta = (props: SearchMetaProps) => {
  const { title } = props;

  return (
    <Head  data-testid='head'>
      {title && (
        <title key='title'>{title}</title>
      )}
      <meta name="robots" content="noindex, nofollow" />
    </Head>
  );
};

export default SearchMeta;
