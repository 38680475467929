const IconZoom = () => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" fill="9F9F9F" d="M15 9.5C15 12.5376 12.5376 15 9.5 15C6.46243 15 4 12.5376 4 9.5C4 6.46243 6.46243 4 9.5 4C12.5376 4 15 6.46243 15 9.5ZM14.1223 15.4067C12.8485 16.4049 11.2438 17 9.5 17C5.35786 17 2 13.6421 2 9.5C2 5.35786 5.35786 2 9.5 2C13.6421 2 17 5.35786 17 9.5C17 11.2438 16.4049 12.8485 15.4067 14.1223L21.734 20.4495C22.0887 20.8042 22.0887 21.3793 21.734 21.734C21.3793 22.0887 20.8042 22.0887 20.4495 21.734L14.1223 15.4067Z" />
    <circle cx="9.5" cy="9.5" r="5.5" fill="9F9F9F" />
    <rect x="9" y="6" width="1" height="7" fill="9F9F9F" />
    <rect x="6" y="10" width="1" height="7" transform="rotate(-90 6 10)" fill="9F9F9F" />
  </svg>
);

export default IconZoom;
