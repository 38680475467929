import styled from '@emotion/styled';
import typography from 'src/styles/typography';
import { StyledThemeProps } from 'src/ui-kit/theme/types';
import Dropdown from '../Dropdown/Dropdown';

export const DesktopSwitchLocaleStyled = styled(Dropdown, {
  shouldForwardProp: (prop) => prop !== 'theme',
})<StyledThemeProps>(({ theme }) => `
  .DesktopSwitchLocale__menuItem:not(:first-of-type) {
    margin-top: 12px;
  }
  .DesktopSwitchLocale__menuLink {
    ${typography.text};
    text-decoration: none;
  }
  .DesktopSwitchLocale__menuLinkActive,
  .DesktopSwitchLocale__menuLink:hover,
  .DesktopSwitchLocale__menuLink:active {
    color: ${theme.base.color.primary};
  }
`);

