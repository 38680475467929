import { phone, phone_tablet } from "src/styles/media";
import css from "styled-jsx/css";

export const styles = css`
  .CategoriesTags {
    margin: 32px 0;
  }
  .CategoriesTags__wrapper{
    margin-bottom: 12px;
  }
  .CategoriesTags__item {
    display: inline-block;
    margin-right: 12px;
    margin-bottom: 12px;
  }

  @media ${phone_tablet} {
    .CategoriesTags {
      margin: 20px 0 12px;
    }
  }

  @media ${phone} {
    .CategoriesTags__wrapper {
      display: flex;
      overflow: auto;
      margin-bottom: 20px;
    }
    .CategoriesTags__item {
      margin-bottom: 0;
      margin-right: 8px;
      flex-shrink: 0;
    }
  }
`;

