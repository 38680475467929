
import cn from 'classnames';
import { SyntheticEvent, useState } from 'react';
import { IS_SERVER, MOBILE_PLATFORM } from '../../../../data/constants';
import { MIN_DESKTOP_WIDTH } from '../../../../styles/media';
import useCursorPosition from '../../../../utils/useCursorPosition';
import styles from './ZoomableImageSwitcher.styles';

export interface ZoomableImageSwitcherProps {
  smallImageSrc: string;
  largeImageSrc: string;
  alt: string;
  title: string;
  safetyBorder: number;
  withHalfOpacity: boolean;
  onImageClick(src: string): void;
}

type MaxTranslations = {
  x: number;
  y: number;
}

type LargeImageDimensions = {
  width: number;
  height: number;
}

const ZoomableImageSwitcher = (props: ZoomableImageSwitcherProps) => {
  const {
    safetyBorder,
    onImageClick,
    withHalfOpacity,
    largeImageSrc,
    smallImageSrc,
    alt,
    title,
  } = props;

  const {
    ref: imageWrapperRef,
    dimensions: {
      width: containerWidth,
      height: containerHeight,
    },
    position: {
      x,
      y,
    },
    isActive,
  } = useCursorPosition();

  const [maxTranslations, setMaxTranslations] = useState<MaxTranslations | null>(null);
  const [largeImageDimensions, setLargeImageDimensions] = useState<LargeImageDimensions | null>(null);

  const handleLargeImageLoad = (
    event: SyntheticEvent<HTMLImageElement> & { target: HTMLImageElement },
  ) => {
    const largeImageWidth = event.target.clientWidth;
    const largeImageHeight = event.target.clientHeight;

    const maxTranslations: MaxTranslations = {
      x: largeImageWidth - containerWidth,
      y: largeImageHeight - containerHeight,
    };

    setMaxTranslations(maxTranslations);

    setLargeImageDimensions({
      width: largeImageWidth,
      height: largeImageHeight,
    });
  };

  const getCorrectedTranslationValue = (
    val: number, min: number, max: number,
  ) => {
    if (val < min) {
      return min;
    }

    if (val > max) {
      return max;
    }

    return val;
  };

  const getLargeImageTranslations = () => {
    if (!largeImageDimensions || !maxTranslations) {
      return `translate3D(0, 0, 0)`;
    }

    const activeContainerWidth = containerWidth - safetyBorder * 2;
    const activeContainerHeight = containerHeight - safetyBorder * 2;

    const activeX = x - safetyBorder;
    const activeY = y - safetyBorder;

    const desiredX = activeX / activeContainerWidth * maxTranslations.x;
    const desiredY = activeY / activeContainerHeight * maxTranslations.y;
    const finalX = getCorrectedTranslationValue(desiredX, 0, maxTranslations.x);
    const finalY = getCorrectedTranslationValue(desiredY, 0, maxTranslations.y);

    return `translate3D(-${finalX}px, -${finalY}px, 0)`;
  };

  const zoomModeIsDesktop =
    (!IS_SERVER && window.innerWidth >= MIN_DESKTOP_WIDTH) && !MOBILE_PLATFORM;

  const shouldShowZoomableImage = isActive && zoomModeIsDesktop;

  return (
    <div
      className='ZoomableImageSwitcher'
      onClick={() => onImageClick(largeImageSrc)}
      ref={imageWrapperRef}
    >
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        src={smallImageSrc}
        alt={alt}
        title={title}
        className={cn(
          'ZoomableImageSwitcher__smallImg', {
          'ZoomableImageSwitcher__smallImg_halfOpacity': withHalfOpacity,
        },
        )}
      />
      {
        shouldShowZoomableImage && (
          // eslint-disable-next-line @next/next/no-img-element
          <img
            src={largeImageSrc}
            alt={alt}
            title={title}
            className='ZoomableImageSwitcher__largeImg'
            onLoad={handleLargeImageLoad}
            style={
              {
                transform: getLargeImageTranslations(),
                transformOrigin: '50% 50%',
              }
            }
          />
        )
      }
      <style jsx>{styles}</style>
    </div>
  );
};

export default ZoomableImageSwitcher;
