import { useRouter } from "next/router";
import { Filter } from "src/api/types";
import { getQueryWithMergedFilterParams } from "src/modules/filters";
import getActiveFiltersObjects from "../../filters/utils/getActiveFiltersObjects";

const useActiveFilters = (filters: Filter[]) => {
  const { query } = useRouter();

  const queryWithMergedFilterParams = getQueryWithMergedFilterParams(query);
  const activeFilters = getActiveFiltersObjects(queryWithMergedFilterParams, filters);

  return {
    activeFilters,
  };
};

export default useActiveFilters;
