import { getDataFromResponse } from "src/utils/api/getDataFromResponse";
import { Category, CategoryProductsResponse } from "./types";
import { fetchWithMemcached } from "src/utils/ApiCacheClient/fetchWithMemcached";
import { fetcher } from "./fetch";

export const getCategories = async (
  language: string,
): Promise<Category[]> => {
  const url = '/uber_catalog/categories/';

  const res = await fetchWithMemcached<Category[]>({
    url,
    cacheKey: `categories-${language}`,
    fetchOptions: {
      language,
    },
  });

  return getDataFromResponse(res, []);
};

export const getCategory = async (
  language: string,
  categoryId: string,
) => {
  const url = `/uber_catalog/categories/${categoryId}`;

  const res = await fetchWithMemcached<Category>({
    url,
    cacheKey: `category-${categoryId}-${language}`,
    fetchOptions: { language },
  });
  return res;
};

interface Params {
  language: string;
  searchString: string;
}

export const fetchCategoryData = async (params: Params) => {
  const {
    language,
    searchString,
  } = params;

  const url = `/uber_catalog/products/${searchString}`;
  const res = await fetchWithMemcached<CategoryProductsResponse>({
    url,
    cacheKey: `category-products-${searchString}-${language}`,
    fetchOptions: { language },
  });

  return res;
};

export const fetchCategoryDataWithoutCache = async (params: Params) => {
  const {
    language,
    searchString,
  } = params;

  const url = `/uber_catalog/products/${searchString}`;
  const res = await fetcher<CategoryProductsResponse>(
    url,
    { language },
  );

  return res;
};

