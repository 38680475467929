/* istanbul ignore file */

import { fetcher } from 'src/api/fetch';

export interface GetNotificationsResult {
  chain: string;
}

export const getNotifications = async (id: string): Promise<string | null> => {
  const url = `/user/sms_notifications/${id}`;

  const response = await fetcher<GetNotificationsResult>(url);

  if (response.status === 'success') {
    return response.data.chain || null;
  }

  return null;
};

export default getNotifications;
