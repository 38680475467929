import getExternalLinks, { MenuLinkData } from "src/data/externalLinks";
import { sendHrFooterClick } from "src/utils/enhancedEcommerce";

interface FooterExternalLink extends MenuLinkData {
  onClick?(): void;
  dataMarker?: string;
}

export const getFooterExternalLinks = (language: string, country: string): FooterExternalLink[] => {
  const footerSpecificExternalLinks = {
    ua: [
      {
        href: 'https://info.zakaz.ua/hr/',
        label: 'link_vacancies',
        onClick: sendHrFooterClick,
        dataMarker: 'Link Vacancies Footer',
      },
    ],
  };

  return [
    ...getExternalLinks(language, country),
    ...footerSpecificExternalLinks[country],
  ];
};

export const getFooterInternalLinks = (language: string, country: string): MenuLinkData[] => {
  const links: Record<string, MenuLinkData[]> = {
    ua: [
      {
        label: 'pages_help',
        href: `https://help.zakaz.ua/hc/${language === 'en' ? 'en-us' : language}/`,
      },
      {
        label: 'pages_contacts',
        href: `/${language}/contacts/`,
      },
      {
        label: 'pages_policy',
        href: `/${language}/policy/`,
      },
      {
        label: 'pages_terms',
        href: `/${language}/terms/`,
      },
    ],
  };

  return links[country];
};
