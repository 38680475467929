import cn from 'classnames';
import { CustomNavigationSliderV2Props } from 'src/components/SliderV2/types';
import { useTheme } from 'src/ui-kit/theme';
import { ImageUrls } from '../../../../api/types';
import { BulletsNavigationStyled } from './BulletsNavigation.styles';

interface Props extends CustomNavigationSliderV2Props {
  gallery: ImageUrls[];
}

const BulletsNavigation = (props: Props) => {
  const { controls: { onGoToSlide, sliderState }, gallery } = props;

  const theme = useTheme();

  const handleButtonClick = (i: number) => {
    if(typeof onGoToSlide === 'function') {
      onGoToSlide(i);
    }
  };

  return (
    <BulletsNavigationStyled
      theme={theme}
      className="BulletsNavigation"
    >
      {gallery.map((_, i) => (
        <button
          type="button"
          key={i}
          className={cn(
            'BulletsNavigation__bullet', {
              'BulletsNavigation__bullet_active': i === sliderState?.currentPosition,
            },
          )}
          onClick={() => handleButtonClick(i)}
        />
      ))}
    </BulletsNavigationStyled>
  );
};

export default BulletsNavigation;
