import { useState } from "react";
import { Filter } from "src/api/types";

const useCatalogFiltersMobile = () => {
  const [isFilterMenuOpened, handleFilterMenuOpened] = useState(false);
  const [selectedFilterType, setSelectedFilterType] = useState<Filter | null>(null);

  const openFilter = () => {
    handleFilterMenuOpened(true);
  };

  const closeFilter = () => {
    handleFilterMenuOpened(false);
    setSelectedFilterType(null);
  };

  const closeCategory = () => {
    setSelectedFilterType(null);
  };


  return {
    isFilterMenuOpened,
    selectedFilterType,
    setSelectedFilterType,
    openFilter,
    closeFilter,
    closeCategory,
  };
};

export default useCatalogFiltersMobile;
