import { UberCatalogProduct } from "src/api/types";
import { BASE_URL, CURRENCY } from "src/data/constants";
import { LocalizeFunc } from "src/services/localize/useLocalizeService";
import formatWeight from "src/utils/products/formatWeight";
import { getProductUrl } from "./getProductUrl";

interface EanObj {
  gtin13?: string;
  sku?: string;
}

interface Props {
  lang: string;
  product: UberCatalogProduct;
  localize: LocalizeFunc;
}

export function generateProductSchema(props: Props) {
  const { product, lang, localize } = props;
  const {
    title,
    ean,
    description,
    price_details,
    in_stock,
    category_id,
    unit,
    weight,
    gallery,
    producer,
    excisable,
    slug,
  } = product;

  if(excisable) {
    return {};
  }

  const image = Array.isArray(gallery)
    ? gallery.map(images => images['s350x350'])
    : [];

    const country = product.country
    ? `${product.country[0].toUpperCase()}${product.country.slice(1)}`
    : '';

  const trademark = producer?.trademark || '';
  const schemaType = in_stock ? "InStock" : "OutOfStock";

  const eanIsValid = (/^\d{13}$/).test(ean);
  let eanObj: EanObj;

  if(eanIsValid) {
    eanObj = { gtin13: ean };
  } else {
    const shouldCutEan = (/^0\d{13}$/).test(ean);

    eanObj = shouldCutEan
      ? { gtin13: ean.slice(1) }
      : { sku: ean };
  }

  const url = `${BASE_URL}${getProductUrl({ lang, ean, slug })}`;

  const productSchema = {
    '@context': 'https://schema.org',
    '@type': 'Product',
    url,
    ...eanObj,
    ...(!!trademark && {
      brand: {
        "@type": "Thing",
        name: trademark,
      },
    }),
    name: title || '',
    description: description || '',
    image,
    category: category_id,
    offers: {
      '@type': 'AggregateOffer',
      lowPrice: price_details.price_from,
      highPrice: price_details.price_to,
      priceCurrency: CURRENCY,
      availability: `https://schema.org/${schemaType}`,
      offerCount: 1,
    },
    additionalProperty: [
      {
        "@type": "PropertyValue",
        name: localize('product_facts_name'),
        value: trademark,
      },
      {
        "@type": "PropertyValue",
        name: localize('product_facts_country'),
        value: country,
      },
      {
        "@type": "PropertyValue",
        name: localize('product_facts_weight'),
        value: formatWeight(unit, weight, null, localize),
      },
    ],
  };

  return productSchema;
}
