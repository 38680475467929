import { getIsVisible } from './getIsVisible';
import getDataStatus from '../../../utils/getDataStatus';

export function setSlidesDataStatus(
  container: HTMLElement,
  inaccuracy: number = 0.5,
): void {
  const slides: HTMLCollection = container.children;

  Array.prototype.forEach.call(slides, (slide: HTMLElement, index): void => {
    const isActive = getIsVisible(container, index, inaccuracy);

    slide.dataset.status = getDataStatus(isActive);
  });
}
