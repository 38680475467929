export type ImageSize = 's200x200' | 's150x150' | 's350x350' | 's1350x1350';

interface HumanImageSizes {
  [name: string]: ImageSize;
}

export const imageSizes: HumanImageSizes = {
  preview: 's150x150',
  small: 's200x200',
  medium: 's350x350',
  large: 's1350x1350',
};

export const defaultProductImage = 'https://img4.zakaz.ua/mainsite-images/default_img.svg';
