import styled from '@emotion/styled';
import { StyledThemeProps } from 'src/ui-kit/theme/types';
import { tablet } from '../../../../styles/media';

export const ThumbsNavigationStyled = styled('div', {
  shouldForwardProp: (prop) => prop !== 'theme',
})<StyledThemeProps>(({ theme }) => `
  height: 72px;
  position: relative;
  max-width: 368px;

  .ThumbsNavigation__image {
    max-width: 100%;
    max-height: 100%;
  }

  .ThumbsNavigation__slide {
    cursor: pointer;
    padding: 0 4px;
  }

  .ThumbsNavigation__imageWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 72px;
    height: 72px;
    border-radius: 0;
    border: solid 1px ${theme.base.color.gray200};
  }

  .ThumbsNavigation__slide_active .ThumbsNavigation__imageWrapper  {
    border-color: ${theme.base.color.primary};
  }

  @media ${tablet} {
    max-width: 344px;
  }
`);
