/* istanbul ignore file */

import { fetcher } from 'src/api/fetch';
import { RetailChain } from 'src/api/types';
import { getDataFromResponse } from 'src/utils/api/getDataFromResponse';

export const getSingleRetailChain = async (chain: string): Promise<RetailChain | null> => {
  const url = `/retail_chains/${chain}`;

  const response = await fetcher<RetailChain>(url);

  return getDataFromResponse(response, null);
};

export default getSingleRetailChain;
