import classNames from 'classnames';
import { ReactNode, useState } from 'react';
import IconFont from 'src/ui-kit/IconFont';
import { Typography } from 'src/ui-kit/Typography';
import getDataStatus from 'src/utils/getDataStatus';
import { styles } from './CollapsePanel.styles';

export interface CollapsePanelProps {
  label: string;
  openedByDefault?: boolean;
  children: ReactNode;
  dataMarkerValue?: string;
}

const CollapsePanel = (props: CollapsePanelProps) => {
  const { openedByDefault = false, dataMarkerValue = 'Collapsible filter', label, children } = props;
  const [isOpen, setIsOpen] = useState(openedByDefault);

  return (
    <div
      className="CollapsePanel"
      data-testid='collapse_panel'
      data-marker={dataMarkerValue}
      date-status={getDataStatus(isOpen)}
    >
      <div
        className='CollapsePanel__header'
        onClick={() => setIsOpen(!isOpen)}
      >
        {label && (
          <Typography variant='text5' >
            {label}
          </Typography>
        )}

        <span
          className={classNames('CollapsePanel__icon', {
            CollapsePanel__icon_active: isOpen,
          })}
        >
          <IconFont
            name='caret-bottom'
            size='10px'
          />
        </span>
      </div>
      {isOpen && (
        <div className='CollapsePanel__content'>{children}</div>
      )}
      <style jsx>{styles}</style>
    </div>
  );
};

export default CollapsePanel;
