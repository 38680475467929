import { AddressManagementBarProps, useAmSize } from "src/modules/address-management";
import { useModals } from "src/modules/modals";
import { ProductRetailsInfoProps } from "src/modules/retails";
import useLocalize from "src/services/localize/useLocalize";
import { WhereOrderProps } from "../components/WhereOrder/WhereOrder";
import { sendProductRetailCardClick } from "../helpers/analytics";

const useWhereOrderProps = (ean: string): WhereOrderProps => {
  const componentsSize = useAmSize();
  const modals = useModals();
  const localize = useLocalize();

  const handleAmButtonClick = () => {
    modals.openModal('addressManagement');
  };

  const retailsInfoProps: ProductRetailsInfoProps = {
    size: componentsSize,
    onClickOpenAm: handleAmButtonClick,
    onRetailInfoCardClick: (isAvailable: boolean, chainId: string) => {
      if(isAvailable) {
        sendProductRetailCardClick({
          place: 'item_card',
          ean,
          network: chainId,
        });
      }
    },
    ean,
    unavailableListLabels: {
      label: localize('product_unavailable_for_retails'),
      button: localize('product_unavailable_for_retails_2'),
    },
  };

  const addressManagementProps: AddressManagementBarProps = {
    onAmButtonClick: handleAmButtonClick,
    size: componentsSize,
  };

  return {
    retailsInfoProps,
    addressManagementProps,
  };
};

export default useWhereOrderProps;
