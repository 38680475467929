import css from 'styled-jsx/css';

const styles = css`
  .FlexList {
    display: flex;
    flex-wrap: wrap;
  }

  .FlexList__item {
    min-width: 0;
  }

  .FlexList_large {
    margin: -8px -8px;
  }

  .FlexList_large .FlexList__item {
    flex: 0 0 ${100 / 3}%;
    padding: 8px 8px;
  }

  .FlexList_medium {
    margin: -6px -6px;
  }

  .FlexList_medium .FlexList__item {
    flex: 0 0 50%;
    padding: 6px 6px;
  }

  .FlexList_small {
    margin: -6px auto;
  }

  .FlexList_small .FlexList__item {
    flex: 0 0 100%;
    padding: 6px 0;
  }
`;

export default styles;
