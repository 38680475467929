import styled from '@emotion/styled';
import { phone, tablet } from 'src/styles/media';
import { StyledThemeProps } from 'src/ui-kit/theme/types';
import typography from '../../styles/typography';

export const GeneralDrawerModalLayoutStyled = styled('div', {
  shouldForwardProp: (prop) => prop !== 'theme',
})<StyledThemeProps>(({ theme }) => `
  position: relative;
  width: 812px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: ${theme.base.color.gray100};
  text-align: left;
  padding: 34px 0 0;

  .GeneralDrawerModalLayout__header {
    min-height: 28px;
    padding: 0 32px;
    margin-bottom: 26px;
  }
  .GeneralDrawerModalLayout__title {
    ${typography.h2}
    padding-right: 60px;
  }
  .GeneralDrawerModalLayout__buttonClose {
    position: absolute;
    top: 30px;
    right: 24px;
    width: 40px;
    height: 40px;
    padding: 12px 0;
    margin-left: auto;
    border: 0;
    background: transparent;
    text-align: center;
    cursor: pointer;
  }
  .GeneralDrawerModalLayout__content {
    flex-grow: 1;
    min-height: 0;
    overflow: auto;
  }

  @media ${ tablet } {
    width: 768px;
  }

  @media ${ phone } {
    width: 100vw;
    padding: 18px 0 12px;

    .GeneralDrawerModalLayout__header {
      padding: 0 16px;
    }

    .GeneralDrawerModalLayout__buttonClose {
      top: 10px;
      right: 8px;
    }

    .GeneralDrawerModalLayout__title {
      ${typography.h3};
    }
  }
`);
