import { RefObject } from "react";
import Tab from "src/components/TabsPanel/Tab";
import TabsPanel from "src/components/TabsPanel/TabsPanel";
import useLocalize from "src/services/localize/useLocalize";
import ProductContent, { ProductContentProps } from "../ProductContent/ProductContent";
import WhereOrder, { WhereOrderProps } from "../WhereOrder/WhereOrder";
import { styles } from "./ProductPageTabs.styles";

export interface ProductPageTabsProps {
  inStock: boolean;
  onTabClick(index: number): void;
  activeTabIndex: number;
  tabsContainerRef: RefObject<HTMLDivElement>;
  productContentProps: ProductContentProps;
  whereOrderProps: WhereOrderProps;
}

const ProductPageTabs = (props: ProductPageTabsProps) => {
  const {
    productContentProps,
    whereOrderProps,
    inStock,
    activeTabIndex,
    tabsContainerRef,
    onTabClick,

  } = props;

  const localize = useLocalize();

  const dataMarkerValueForProductContent = 'About product';

  if (!inStock) {
    return (
      <div title={localize('products.about-title')} data-marker={dataMarkerValueForProductContent}>
        <div className="ProductPageTabs">
          <ProductContent {...productContentProps} />
          <style jsx>{styles}</style>
        </div>
      </div>
    );
  }

  return (
    <div className="ProductPageTabs" ref={tabsContainerRef}>
      <div className="ProductPageTabs__tabsPanel">
        <TabsPanel
          onTabClick={onTabClick}
          activeTabIndex={activeTabIndex}
        >
          <Tab title={localize('where_order')} dataMarker="Where order">
            <div className='ProductPageTabs__tab'>
              <WhereOrder {...whereOrderProps} />
            </div>
          </Tab>
          <Tab title={localize('products.about-title')} dataMarker={dataMarkerValueForProductContent}>
            <div className='ProductPageTabs__tab'>
              <ProductContent {...productContentProps} />
            </div>
          </Tab>
        </TabsPanel>
      </div>
      <style jsx>{styles}</style>
    </div>
  );
};

export default ProductPageTabs;
