import { RangeFilter } from "src/api/types";
import CollapsePanel from "src/components/CollapsePanel/CollapsePanel";
import Button from "src/ui-kit/Button";
import FilterPrice from "src/ui-kit/FilterPrice/FilterPrice";
import StylishBox from "src/ui-kit/StylishBox/StylishBox";
import useFilterPriceWrap from "../../hooks/useFilterPriceWrap";

export interface FilterPriceWrapProps {
  filter: RangeFilter;
}

const FilterPriceWrapDesktop = (props: FilterPriceWrapProps) => {
  const { filter } = props;

  const { filterPriceProps, onSubmit, filterName } = useFilterPriceWrap(filter);

  return (
    <CollapsePanel
      label={filterName}
      openedByDefault={true}
    >
      <FilterPrice
        {...filterPriceProps}
        onSubmit={onSubmit}
        buttonSlot={
          <StylishBox sbs={{ ml: '8px' }}>
            <Button
              variant='ghost'
              onClick={onSubmit}
              dataMarker='Filter Price OK'
            >
              OK
            </Button>
          </StylishBox>
        }
      />
    </CollapsePanel>
  );
};

export default FilterPriceWrapDesktop;
