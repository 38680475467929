import { Fragment } from 'react';
import Input from 'src/UIKitOld/Input/Input';
import { Filter } from 'src/api/types';
import useLocalize from 'src/services/localize/useLocalize';
import IconFont from 'src/ui-kit/IconFont';
import StylishBox from 'src/ui-kit/StylishBox/StylishBox';
import Typography from 'src/ui-kit/Typography/Typography';
import { useTheme } from 'src/ui-kit/theme';
import getFilterOptionIsChecked from '../../utils/getFilterOptionIsChecked';
import CheckListItem from './CheckListItem/CheckListItem';
import EmptyListMessage from './EmptyListMessage';
import useCheckList from './useCheckList';

export interface CheckListProps {
  hasSearch?: boolean;
  isMobileMode?: boolean;
  filter: Filter;
}

const CheckList = (props:CheckListProps) => {
  const {
    hasSearch,
    filter,
    isMobileMode,
  } = props;

  const localize = useLocalize();
  const theme = useTheme();

  const {
    isOpen,
    setIsOpen,
    searchBoxIsVisible,
    withToggleButton,
    searchWord,
    handleClearSearchWord,
    handleChangeSearchWord,
    optionsCount,
    options,
    selectedQueryFilters,
    handleFilterClick,
  } = useCheckList({ hasSearch, filter, isMobileMode });

  return (
    <StylishBox
      sbs={{
        pt: {
          tablet: searchBoxIsVisible ? '16px' : '0',
        },
      }}
    >
      {searchBoxIsVisible && (
        <StylishBox
          sbs={{
            mb: {
              desktop: '16px',
            },
            p: {
              tablet: '0 32px 16px',
              phone: '12px 16px',
            },
          }}
        >
          <StylishBox
            sbs={{
              position: 'relative',
            }}
          >
            <Input
              dataMarker='Check list search field'
              placeholder={localize('search')}
              value={ searchWord }
              onChange={ handleChangeSearchWord }
              testId='search-field'
            />

            {searchWord.length > 0 ? (
              <StylishBox
                element='span'
                elementProps={{
                  onClick: handleClearSearchWord,
                  'aria-label': 'clear',
                }}
                sbs={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '34px',
                  height: '34px',
                  cursor: 'pointer',
                  position: 'absolute',
                  top: '3px',
                  right: '10px',
                  color: theme.base.color.gray400,
                  hover: {
                    color: theme.base.color.gray600,
                  },
                }}
              >
                <IconFont name="close" size="10px"  />
              </StylishBox>
            ) : (
              <IconFont
                name="loupe"
                color="gray400"
                sbs={{
                  position: 'absolute',
                  top: '12px',
                  right: '20px',

                }}
              />
            )}
          </StylishBox>
        </StylishBox>
      )}

      {optionsCount === 0 && filter.type !== 'range' && (
          <EmptyListMessage />
      )}

      {optionsCount > 0 && (
        <Fragment>
          <StylishBox
            dataTestId="check-list-desktop"
            sbs={{
              maxHeight: withToggleButton ? '310px' : 'inherit',
              overflowY: 'auto',
              borderTop: {
                phone: `${theme.base.border.v1} ${theme.base.color.gray200}`,
              },
            }}
          >
            {options.map(option => {
              const isChecked = getFilterOptionIsChecked(selectedQueryFilters, filter.key, option.value);

              return (
                <CheckListItem
                  key={`${option.value}_${option.name}`}
                  filter={filter}
                  option={option}
                  onClick={handleFilterClick}
                  isChecked={isChecked}
                />
              );
            })}
          </StylishBox>
          {withToggleButton && (
            <Typography
              variant="text5"
              color='primary'
              sbs={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                mt: '16px',
              }}
              elementProps={{
                onClick: () => setIsOpen(!isOpen),
              }}
              dataMarker='Show more'
            >
              {localize(isOpen ? 'products.show-less' : 'products.show-more')}
              <IconFont
                name={isOpen ? 'caret-top' : 'caret-bottom'}
                sbs={{
                  fontSize: '10px',
                  ml: '6px',
                }}
              />
            </Typography>
          )}
        </Fragment>
      )}
    </StylishBox>
  );
};

export default CheckList;
