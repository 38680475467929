import css from 'styled-jsx/css';
import { desktop } from '../../../../../styles/media';

export default css`
  @media ${desktop} {
    .PageWrapAside {
      width: 272px;
    }
  }
}
`;
