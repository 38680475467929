import { phone } from "src/styles/media";
import css from "styled-jsx/css";

export const styles = css`
  .LoginButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    outline: 0;
    border: 0;
    padding: 0;
    cursor: pointer;
  }

  @media ${phone} {
    .LoginButton {
      width: 20px;
      height: 20px;
    }
  }
`;
