import styled from "@emotion/styled";
import { StyledThemeProps } from "src/ui-kit/theme/types";

export const PaginationStyled = styled('div', {
  shouldForwardProp: (prop) => prop !== 'theme',
})<StyledThemeProps>(({ theme }) => `
  display: flex;
  justify-content: center;
  width: 100%;

  .Pagination {
    list-style: none;
    display: flex;
    align-items: center;
    padding: 9px 5px;
    background-color: ${theme.base.color.white};
    border: 1px solid ${theme.base.color.gray200};
    border-radius: 24px;
  }

  .Pagination__break,
  .Pagination__item {
    display: block;
    width: 30px;
    font-size: 12px;
    line-height: 30px;
    border-radius: 50%;
    text-align: center;
    transition: 0.2s all ease-in-out;
    text-decoration: none;
    color: inherit;
  }

  .Pagination__item_active {
    background-color: ${theme.base.color.primary};
    color: ${theme.base.color.white};
  }

  .Pagination__item:not(.pagination__item_active):hover {
    background-color: transparent;
    color: ${theme.base.color.primary};
    cursor: pointer;
  }

  .Pagination__direction {
    padding: 0 8px;
  }

  .Pagination__directionLink {
    display: flex;
    width: 28px;
    height: 28px;
    justify-content: center;
    align-items: center;
    font-size: 22px;

    text-decoration: none;
    color: inherit;
  }

  .Pagination__direction_disabled {
    opacity: 0.5;
  }

  .Pagination__direction:not(.Pagination__direction_disabled),
  .Pagination__direction-link:hover {
    color: ${theme.base.color.primary};
    cursor: pointer;
  }

  .pagination__break {
    color: ${theme.base.color.gray400};
  }
`);
