import { COUNTRY, WITH_UBERCATALOG } from "src/data/constants";
import useLang from "src/services/language/useLang";
import useLocalize from "src/services/localize/useLocalize";
import IconFont from "src/ui-kit/IconFont";
import { getFooterExternalLinks, getFooterInternalLinks } from "./helpers";
import StylishBox from '../../../ui-kit/StylishBox/StylishBox';
import StylishLink from '../../../ui-kit/StylishLink';

const FooterMenu = () => {
  const language = useLang();
  const localize = useLocalize();
  const externalLinks = getFooterExternalLinks(language, COUNTRY);
  const internalLinks = getFooterInternalLinks(language, COUNTRY);

  return (
    <StylishBox
      className="FooterMenu"
      element="ul"
      sbs={{
        pb: { tabletPhone: '10px' },
        fontSize: '16px',
        lineHeight: '1.5',
      }}
    >
      {externalLinks.map(l => (
        <StylishBox
          key={l.label}
          element="li"
          sbs={{ mb: '14px' }}
        >
          <StylishLink
            href={l.href}
            rel="nofollow noopener noreferrer"
            target="_blank"
            onClick={l.onClick}
            data-marker={l.dataMarker}
          >
            {localize(l.label)}
            <IconFont
              name='arrow-up-right'
              size='13px'
              sbs={{ ml: '9px' }}
            />
          </StylishLink>
        </StylishBox>
      ))}

      {/* Simple <a /> because of non-SPA transition to old site */}
      {
        !WITH_UBERCATALOG &&
        <StylishBox
          element="li"
          sbs={{ mb: '14px' }}
        >
          <StylishLink href="/products/">
            {localize('combined_catalog_of_all_products')}
          </StylishLink>
        </StylishBox>
      }

      {
        internalLinks.map((link) => (
          <StylishBox key={link.label} element="li" sbs={{ mb: '14px' }}>
            <StylishLink innerLink href={link.href}>
              {localize(link.label)}
            </StylishLink>
          </StylishBox>
        ))
      }
    </StylishBox>
  );
};

export default FooterMenu;
