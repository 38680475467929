
export function swipeContainer(container: HTMLElement, scrollValue: number, duration = 500) {
  const startScroll = container.scrollLeft;  // начальное положение скролла
  const distance = scrollValue - startScroll; // расстояние, на которое нужно прокрутить
  const startTime = performance.now(); // начальное время анимации

  // Функция для плавной прокрутки с использованием requestAnimationFrame
  function animateScroll(currentTime: number) {
    const elapsedTime = currentTime - startTime;
    const progress = Math.min(elapsedTime / duration, 1); // прогресс анимации от 0 до 1

    // Применяем ease-in-out для плавности анимации
    const easeInOutProgress = progress < 0.5
      ? 2 * progress * progress
      : -1 + (4 - 2 * progress) * progress;

    // Обновляем положение скролла
    container.scrollLeft = startScroll + distance * easeInOutProgress;

    // Продолжаем анимацию, пока не достигнем конца
    if (progress < 1) {
      requestAnimationFrame(animateScroll);
    }
  }

  // Запускаем анимацию
  requestAnimationFrame(animateScroll);
}
