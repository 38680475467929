import { fetcher } from "./fetch";
import { SearchResponseDto } from "./types";

interface Params {
  language: string;
  searchString: string;
}

export const getCatalogSearchProducts = async (params: Params) => {
  const {
    language,
    searchString,
  } = params;

  const url = `/uber_catalog/products/search${searchString}`;
  const res = await fetcher<SearchResponseDto>(url, { language });

  return res;
};
