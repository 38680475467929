import { desktop } from 'src/styles/media';
import css from 'styled-jsx/css';

export const styles = css`
  .CollapsePanel__icon_active {
    transform: rotate(180deg);
  }
  .CollapsePanel__header {
    position: relative;
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 16px;
  }

  @media ${desktop} {
    .CollapsePanel__content {
      padding-top: 16px;
    }
  }
  `;
