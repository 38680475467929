import cn from 'classnames';
import debounce from 'lodash/debounce';
import { Fragment, MouseEvent, useEffect, useRef, useState } from 'react';
import Slider from 'src/components/SliderV2/Slider';
import { useSlider } from 'src/components/SliderV2/helpers/useSlider';
import { ImageUrls } from '../../../../api/types';
import { MOBILE_PLATFORM } from '../../../../data/constants';
import { defaultProductImage, imageSizes } from '../../../../fixtures';
import { useLocalize } from '../../../../services/localize/useLocalize';
import { MIN_DESKTOP_WIDTH, MIN_TABLET_WIDTH } from "../../../../styles/media";
import { getImgAlt, getImgTitle } from '../../../../utils/products/getImgAttributes';
import ProductBtnZoomImage from '../ProductBtnZoomImage/ProductBtnZoomImage';
import ZoomableImage from '../ZoomableImageSwitcher/ZoomableImageSwitcher';
import BulletsNavigation from './BulletsNavigation';
import styles from './ProductImagesGallery.styles';
import ThumbsNavigation from './ThumbsNavigation';

export interface ProductImagesGalleryProps {
  gallery: ImageUrls[];
  productTitle: string;
  withHalfOpacity: boolean;
  onImageClick(src: string): void;
}

const ProductImagesGallery = (props: ProductImagesGalleryProps) => {
  const {
    gallery,
    productTitle,
    withHalfOpacity,
    onImageClick,
  } = props;

  const localize = useLocalize();

  const {sliderRef, controls, sliderControls} = useSlider({
    withSwiperMode: true,
  });
  const [windowWidth, setWindowWidth] = useState<number>(0);

  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(
    () => {
      updateWindowWidth();

      const debouncedSetWindowWidth = debounce(updateWindowWidth, 50);
      window.addEventListener('resize', debouncedSetWindowWidth);

      return () => {
        window.removeEventListener('resize', debouncedSetWindowWidth);
      };
    },
    [],
  );

  const galleryThumbsRef = useRef<HTMLDivElement>(null);

  const imgAlt = getImgAlt(productTitle, localize);
  const imgTitle = getImgTitle(productTitle, localize);

  const isDesktopZoomMode = (windowWidth >= MIN_DESKTOP_WIDTH) && !MOBILE_PLATFORM;
  const hasImage = !!gallery.length;

  const handleClickBtnZoom = (event: MouseEvent, index: number) => {
    event.stopPropagation();

    const currentSlide = gallery[index];
    onImageClick(currentSlide[imageSizes.large]);
  };

  const isTableDesktop = windowWidth >= MIN_TABLET_WIDTH;

  return (
    <Fragment>
      <div
        className='ProductImagesGallery'
      >
        <div
          className='ProductImagesGallery__images'
        >
          <div className='ProductImagesGallery__slider'>
            {hasImage ? (
              <Slider
                sliderRef={sliderRef}
                controls={sliderControls}
              >
                {gallery.map((slide, i) => (
                  <div key={i} className="ProductImagesGallery__slide ProductImagesGallery__imageContainer">
                    {isTableDesktop ? (
                      <ZoomableImage
                        smallImageSrc={slide[imageSizes.medium]}
                        largeImageSrc={slide[imageSizes.large]}
                        alt={`${imgAlt} ${i + 1}`}
                        title={`${imgTitle} ${i + 1}`}
                        safetyBorder={30}
                        onImageClick={onImageClick}
                        withHalfOpacity={withHalfOpacity}
                      />
                    ) : (
                      <div className='ProductImagesGallery__imageContainer'>
                        {/* eslint-disable-next-line @next/next/no-img-element */}
                        <img
                          className="ProductImagesGallery__noZoomImage"
                          src={slide[imageSizes.medium]}
                          alt={`${imgAlt} ${i + 1}`}
                          title={`${imgTitle} ${i + 1}`}
                          onClick={() => onImageClick(slide[imageSizes.large])}
                        />
                      </div>
                    )}
                    {!isDesktopZoomMode && hasImage && (
                      <ProductBtnZoomImage
                        onClick={(e: MouseEvent) => handleClickBtnZoom(e, i)}
                        dataMarker={isTableDesktop ? "Zoom Photo Tablet" : "Zoom Photo Mobile"}
                      />
                    )}
                  </div>
                ))}
              </Slider>
            ) : (
              <div className='ProductImagesGallery__imageContainer'>
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img
                  className={cn(
                    'ProductImagesGallery__defaultImage',
                  )}
                  src={defaultProductImage}
                  alt={imgAlt}
                />
              </div>
            )}
          </div>
          <div
            className={cn({
              'ProductImagesGallery__thumbs': isTableDesktop,
              'ProductImagesGallery__bullets': !isTableDesktop,
            })}
            ref={galleryThumbsRef}
          >
            {isTableDesktop
                ? (
                  <ThumbsNavigation controls={controls} gallery={gallery} productTitle={productTitle} />
                ) : (
                  <BulletsNavigation controls={controls} gallery={gallery} />
                )
              }
          </div>
        </div>
        <style jsx>{styles}</style>
      </div>
    </Fragment>
  );
};

export default ProductImagesGallery;
