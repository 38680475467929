import cn from 'classnames';
import { useState } from 'react';
import useLocalize from 'src/services/localize/useLocalize';
import IconFont from 'src/ui-kit/IconFont';
import { Typography } from 'src/ui-kit/Typography';
import { useTheme } from 'src/ui-kit/theme';
import { SortOption } from '../../utils/sortContent';
import { ContentSortDropdownStyled } from './ContentSortDropdown.styles';

interface ContentSortDropdownProps {
  label: string;
  items: SortOption[];
  active: SortOption;
  onChange: (item: SortOption) => void;
  withLeftAlign?: boolean;
  dataMarker?: string;
}

export const ContentSortDropdown = (props: ContentSortDropdownProps) => {
  const {
    items,
    active,
    onChange,
    label,
    withLeftAlign,
    dataMarker = 'ContentSortDropdown',
  } = props;

  const localize = useLocalize();
  const theme = useTheme();

  const [isExpanded, setIsExpanded] = useState(false);

  const handleClose = () => setIsExpanded(false);
  const handleOpen = () => setIsExpanded(true);

  const getHandleChange = (option: SortOption) => () => {
    handleClose();
    onChange(option);
  };

  return (
    <ContentSortDropdownStyled
      theme={theme}
      className="ContentSortDropdown"
      tabIndex={0}
      onBlur={handleClose}
      data-marker={`${dataMarker} Box`}
    >
      <div className="ContentSortDropdown__line">
        <div className="ContentSortDropdown__label">{label}</div>
        <div
          className="ContentSortDropdown__selected"
          onClick={handleOpen}
          data-marker={`${dataMarker} Button`}
          data-testid="sort-button"
        >
          <span>
            {localize(active.translation_key)}
          </span>

          <IconFont
            name={isExpanded ? 'caret-top' : 'caret-bottom'}
            size="10px"
            sbs={{ ml: '6px' }}
          />
        </div>
      </div>
      <div
        className={cn('ContentSortDropdown__list', {
          ContentSortDropdown__list_expanded: isExpanded,
          ContentSortDropdown__list_withLeftAlign: withLeftAlign,
        })}
        data-testid="sort-dropdown"
        data-marker={`${dataMarker} List`}
      >
        {items.map((item) => {
          const itemIsActive = active.value === item.value;
          const itemStatus = itemIsActive ? 'active' : 'inactive';

          return (
            <div
              className={cn('ContentSortDropdown__item', {
                ContentSortDropdown__item_active: itemIsActive,
              })}
              onClick={getHandleChange(item)}
              key={item.value}
              data-status={itemStatus}
              data-marker={`${dataMarker} ${item.value}`}
              data-testid={item.value}
            >
              <Typography
                variant={itemIsActive ? 'text5' : 'text6'}
                sbs={{
                  lineHeight: '24px',
                  hover: {
                    fontWeight: '600',
                  },
                }}
                element='span'
              >
                {localize(item.translation_key)}
              </Typography>
              {itemIsActive && (
                <IconFont
                  name="check"
                  sbs={{
                    fontSize: '12px',
                    transform: 'translateY(-50%)',
                    position: 'absolute',
                    top: '50%',
                    right: '22px',
                  }}
                />
              )}

            </div>
          );
        })}
      </div>
    </ContentSortDropdownStyled>
  );
};

export default ContentSortDropdown;
