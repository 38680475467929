type CategoryPlace = 'category_tiles' | 'category_bread_crumbs' | 'category_tags' | 'category_tree' | 'search_category' | 'category_category_tiles';
export type Page = `category_lvl${number}` | 'landing' | 'catalog';

interface CategoryClickParams {
  place: CategoryPlace;
  page: Page;
  category_id: string;
  slider_index?: number;
}

export const sendCategoryClick = (params: CategoryClickParams) => {
  window.dataLayer.push({
    event: "category_click",
    ...params,
  });
};

type ProductPlace = 'item_card';

interface AddToCartClickParams {
  place: ProductPlace;
  ean: string;
}

export const sendAddToCartClick = (params: AddToCartClickParams) => {
  window.dataLayer.push({
    event: "ubercatalog_add_to_cart_click",
    ...params,
  });
};

interface ProductRetailCardClickParams {
  place: ProductPlace;
  network: string;
  ean: string;
}

export const sendProductRetailCardClick = (params: ProductRetailCardClickParams) => {
  window.dataLayer.push({
    event: "ubercatalog_to_the_store_click",
    ...params,
  });
};
