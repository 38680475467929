export interface MenuLinkData {
  href: string;
  label: string;
}

type MenuLinksMap = Record<string, MenuLinkData[]>;

const getExternalLinks = (language: string, country: string): MenuLinkData[] => {
  const links: MenuLinksMap = {
    ua: [
      {
        href: `https://b2b.zakaz.ua/${language}/`,
        label: 'link_for_business',
      },
      {
        href: `https://forbrand.zakaz.ua/${language}/`,
        label: 'link_for_brands',
      },
      {
        href: `https://content.zakaz.global/${language}/`,
        label: 'link_for_suppliers',
      },
    ],
  };

  return links[country];
};

export default getExternalLinks;
