
import { useCallback, useEffect, useRef, useState } from "react";
import { clampValueToLimits } from "src/modules/filters";
import { useStableFunction } from "src/utils/useStableFunction";
import { RangeNumbers, SliderFieldProps } from "./SliderField";
import { digitsByAsc, doesSupportTouchActionNone, getActiveTrackPosition, getNewPointPercent, getNewPointPx, percentToValue, roundValueToStep, valueToPercent } from "./utils";

const useSliderField = (props: SliderFieldProps) => {
  const { min = 0, max = 100, step = 1, onChange } = props;

  const value = Array.isArray(props.value)
    ? [...props.value].sort(digitsByAsc) as RangeNumbers
    : props.value;

  const rootRef = useRef<HTMLDivElement>(null);
  const activeThumbIndexRef = useRef<number | null>(null);
  const touchIdRef = useRef<number>();
  // Использую эту переменную в обработчике touchMove.
  // События браузера mouseMove происходят чаше чем установка состояния
  // и ререндер компонента. При следующем вызове обработчика,
  // value иногда может иметь старое значение. Это вызывает баг в
  // определении индекса активного ползунка. Для фикса бага использую реф,
  // который не зависит от состояния и рендеринга
  const valueRef = useRef<number | RangeNumbers | null>(null);
  const [movingMode, setMovingMode] = useState<boolean>(false);
  const thumbsPositions = (Array.isArray(value) ? value : [value]).map((v) =>
    valueToPercent(clampValueToLimits(v, min, max), min, max),
  );

  const activeTrackPosition = getActiveTrackPosition(thumbsPositions);

  const getIndexOfClosestThumb = (newPointPercent: number) => {
    const distanceToNewValue = thumbsPositions.map((v) =>
      Math.abs(newPointPercent - v),
    );
    return distanceToNewValue[1] - distanceToNewValue[0] > 0 ? 0 : 1;
  };

  const getNewValue = (event: MouseEvent | TouchEvent | React.MouseEvent): number | RangeNumbers | null => {
    const newPointPx = getNewPointPx(event, touchIdRef);

    if (newPointPx !== false) {
      // мы не можем делать это за пределами этой функции из-за того,
      // что при ререндере value может иметь не актуальное значение которое запишется в реф.
      const prevValue = valueRef.current !== null
        ? valueRef.current
        : value;

      const newPointPercent = getNewPointPercent(newPointPx, rootRef.current);

      let newPointValue = percentToValue(newPointPercent, min, max);

      if (Array.isArray(value) && activeThumbIndexRef.current === null ) {
        activeThumbIndexRef.current = getIndexOfClosestThumb(newPointPercent);
      }

      if (step) {
        newPointValue = roundValueToStep(newPointValue, step, min);
      }

      let newValue: number | RangeNumbers = clampValueToLimits(newPointValue, min, max);

      if (Array.isArray(prevValue) && activeThumbIndexRef.current !== null) {
        const newValueArr: RangeNumbers = [...prevValue];

        newValueArr[activeThumbIndexRef.current] = newValue;
        newValue = newValueArr;

        //swap active thumb index if items change places
        if(newValue[0] > newValue[1]) {
          activeThumbIndexRef.current = 1 - activeThumbIndexRef.current;
          newValue.sort(digitsByAsc);
        } else if (newValue[0] === newValue[1]) {
          const activeThumbIndex = activeThumbIndexRef.current;
          newValue[activeThumbIndex] = activeThumbIndex === 0 ? newValue[0] - step: newValue[1] + step;
        }

        // clamp value of not changed thumb
        const notActiveThumbIndex = 1 - activeThumbIndexRef.current;
        newValue[notActiveThumbIndex] = clampValueToLimits(newValue[notActiveThumbIndex], min, max);
      }

      valueRef.current = newValue;
      return newValue;
    }

    return null;
  };

  const handleMouseDown = useStableFunction((e: React.MouseEvent) => {
    if (e.button !== 0 || e.defaultPrevented) {
      return;
    }

    e.preventDefault();

    const newValue = getNewValue(e);

    if(newValue !== null) {
      onChange(newValue);
    }

    document.addEventListener('mousemove', handleTouchMove);
    document.addEventListener('mouseup', handleTouchEnd);
  });

  const handleTouchStart = useStableFunction((e: TouchEvent) => {
    // If touch-action: none; is not supported we need to prevent the scroll manually.
    if (!doesSupportTouchActionNone()) {
      e.preventDefault();
    }

    const touch = e.changedTouches[0];
    if (touch != null) {
      // A number that uniquely identifies the current finger in the touch session.
      touchIdRef.current = touch.identifier;
    }

    const newValue = getNewValue(e);

    if(newValue !== null) {
      onChange(newValue);
    }

    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleTouchEnd);
  });

  const handleTouchMove = useStableFunction((e: MouseEvent) => {
    // Cancel move in case some other element consumed a mouseup event and it was not fired.
    if (e.type === 'mousemove' && e.buttons === 0) {
      handleTouchEnd();
      return;
    }

    setMovingMode(true);

    const newValue = getNewValue(e);

    if (newValue !== null) {
      onChange(newValue);
    }
  });

  const handleTouchEnd = useStableFunction(() => {
    valueRef.current = null;
    activeThumbIndexRef.current = null;
    setMovingMode(false);
    removeListeners();
  });

  const removeListeners = useCallback(() => {
    document.removeEventListener('mousemove', handleTouchMove);
    document.removeEventListener('mouseup', handleTouchEnd);
    document.removeEventListener('touchmove', handleTouchMove);
    document.removeEventListener('touchend', handleTouchEnd);
  }, [handleTouchMove, handleTouchEnd]);

  useEffect(() => {
    const { current: rootEl } = rootRef;
    rootEl?.addEventListener('touchstart', handleTouchStart, {
      passive: doesSupportTouchActionNone(),
    });

    return () => {
      rootEl?.removeEventListener('touchstart', handleTouchStart);

      removeListeners();
    };
  }, [removeListeners, handleTouchStart]);

  return {
    handleMouseDown,
    movingMode,
    rootRef,
    activeTrackPosition,
    thumbsPositions,
  };

};

export default useSliderField;
