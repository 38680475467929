import cn from 'classnames';
import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { ImageUrls } from '../../../../api/types';
import { imageSizes } from '../../../../fixtures';
import { useLocalize } from '../../../../services/localize/useLocalize';
import { desktop } from '../../../../styles/media';
import { getImgAlt, getImgTitle } from '../../../../utils/products/getImgAttributes';

import Slider from 'src/components/SliderV2';
import { useSlider } from 'src/components/SliderV2/helpers/useSlider';
import { CustomNavigationSliderV2Props } from 'src/components/SliderV2/types';
import { useTheme } from 'src/ui-kit/theme';
import { ThumbsNavigationStyled } from './ThumbsNavigation.styles';
import ThumbsNavigationControls from './ThumbsNavigationControls';

interface Props extends CustomNavigationSliderV2Props {
  gallery: ImageUrls[];
  productTitle: string;
}

const ThumbsNavigation = (props: Props) => {
  const { controls: { onGoToSlide, sliderState }, gallery, productTitle } = props;

  const localize = useLocalize();
  const theme = useTheme();
  const imgAlt = getImgAlt(productTitle, localize);
  const imgTitle = getImgTitle(productTitle, localize);

  const { sliderRef, sliderControls, controls} = useSlider({});
  const isDesktop = useMediaQuery({query: desktop});
  const [controlsAreShown, setControlsAreShown] = useState(false);

  const onMouseEnter = () => {
    const countOfImagesInViewport = 3;
    if (gallery.length > countOfImagesInViewport) {
      setControlsAreShown(true);
    }
  };

  const onMouseLeave = () => {
    setControlsAreShown(false);
  };

  const handleButtonClick = (i: number) => {
    if(typeof onGoToSlide === 'function') {
      onGoToSlide(i);
    }
  };

  return (
    <ThumbsNavigationStyled
      theme={theme}
      data-marker="Gallery thumbs"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Slider
        sliderRef={sliderRef}
        controls={sliderControls}
      >
        {gallery.map((slide, i) => (
          <div key={i}
            className={cn(
              'ThumbsNavigation__slide', {
              'ThumbsNavigation__slide_active': i === sliderState?.currentPosition,
            },
            )}
            onClick={() => handleButtonClick(i)}
          >
            <div className="ThumbsNavigation__imageWrapper">
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img
                src={slide[imageSizes.preview]}
                alt={`${imgAlt} ${i + 1}`}
                title={`${imgTitle} ${i + 1}`}
                className="ThumbsNavigation__image"
              />
            </div>
          </div>
        ))}
      </Slider>
      {
        controls && controlsAreShown && isDesktop && <ThumbsNavigationControls controls={controls} />
      }
    </ThumbsNavigationStyled>
  );
};

export default ThumbsNavigation;
