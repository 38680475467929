import { useRouter } from "next/router";
import { Category, CategoryProductsResponse } from "src/api/types";
import { fetchCategoryData } from "src/api/ubercatalogRequests";
import { OpenGraphMetaProps } from "src/components/OpenGraphMeta";
import useCategories from "src/services/categories/useCategories";
import useLang from "src/services/language/useLang";
import { BreadcrumbsProps } from "../../../components/Breadcrumbs/Breadcrumbs";
import { CatalogMetaProps } from "../components/CatalogMeta/CatalogMeta";
import { CategoryPageContentHeaderProps } from "../components/CategoryPageContentHeader/CategoryPageContentHeader";
import { ProductBoxProps } from "../components/ProductBox/ProductBox";
import { sendCategoryClick } from "../helpers/analytics";
import checkPageIsClosedFromIndexing from "../helpers/checkPageIsClosedFromIndexing";
import { getAggregateOffersMicroData } from "../helpers/getAggregateOffersMicroData";
import getCategoryLevel from "../helpers/getCategoryLevel";
import { useCatalogSorting } from "../hooks/useCatalogSorting";
import useActiveFilters from "./useActiveFilters";
import useBreadcrumbsItems from "./useBreadcrumbsCatalogItems";
import useCategoryTitleDescription from "./useCategoryTitleDescription";
import useLoadMoreAndPagination from "./useLoadMoreAndPagination";
import useLoadingServerData from "./useLoadingServerData";

interface Params {
  selectedCategory: Category;
  categoryData: CategoryProductsResponse;
}

const useCategoryPageProps = (params: Params) => {
  const { selectedCategory, categoryData } = params;

  const { filters, results: products, count_available: countAvailable, count, stats, pagination_limit } = categoryData;
  const { id, description, image_url, title } = selectedCategory;

  const lang = useLang();
  const { query } = useRouter();
  const categories = useCategories();
  const breadcrumbs = useBreadcrumbsItems(id);
  const categoryLevel = getCategoryLevel(categories, id);
  const sortingProps = useCatalogSorting();
  const loading = useLoadingServerData();
  const { activeFilters } = useActiveFilters(filters);

  const hasActiveFilters = activeFilters.length > 0;
  const isVisibleEmptyFilterState = hasActiveFilters && count === 0;
  const descriptionIsVisible = !query?.page && !hasActiveFilters;

  const handleBreadcrumbClick = (id: string) => {
    sendCategoryClick({
      place: 'category_bread_crumbs',
      page: `category_lvl${categoryLevel}`,
      category_id: id,
    });
  };

  const breadcrumbsProps: BreadcrumbsProps = {
    items: breadcrumbs,
    onBreadcrumbClick: handleBreadcrumbClick,
  };

  const productsCount = count > pagination_limit ? pagination_limit : count;

  const loadMoreAndPaginationProps = useLoadMoreAndPagination({
    products,
    productsCount,
    categoryId: id,
    sort: sortingProps.selectedSortItem.value,
    fetchDataHandler: fetchCategoryData,
  });

  const {
    h1,
    metaTitle,
    metaDescription,
  } = useCategoryTitleDescription({
    activeFilters,
    selectedCategory,
    categories,
  });

  const openGraphMetaProps: OpenGraphMetaProps = {
    lang,
    title: metaTitle,
    description: metaDescription,
  };

  const isClosedFromIndexing =  checkPageIsClosedFromIndexing(query);

  const catalogMetaProps: CatalogMetaProps = {
    description: metaDescription,
    title: metaTitle,
    isClosedFromIndexing,
  };

  const categoryPageContentHeaderProps: CategoryPageContentHeaderProps =  {
    title: h1,
    categoryLevel,
    categories,
    selectedCategory,
  };

  const productBoxProps: ProductBoxProps = {
    countAvailable,
    activeFilters,
    sortingProps,
    loadMoreAndPaginationProps,
  };

  const iconSelectedCategory = image_url?.svg || image_url?.png;

  const categoryMicroData = getAggregateOffersMicroData({
    lang,
    stats,
    count,
    products,
    categoryImage: iconSelectedCategory,
    categoryName: title,
    isClosedFromIndexing,
  });

  const descriptionToShow = descriptionIsVisible ? description : '';

  return {
    categoryMicroData,
    openGraphMetaProps,
    catalogMetaProps,
    categoryPageContentHeaderProps,
    loadMoreAndPaginationProps,
    breadcrumbsProps,
    productBoxProps,
    filters,
    description: descriptionToShow,
    loading,
    isVisibleEmptyFilterState,
  };
};

export default useCategoryPageProps;
