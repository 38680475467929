
import { useRouter } from "next/router";
import findCategoryObject from "src/utils/categories/findCategoryObject";
import { getCategoryHrefWithSort } from "../../helpers/getCategoryHrefWithSort";
import { CategoryTagProps } from "../CategoryTag/CategoryTag";
import useLocalize from "src/services/localize/useLocalize";
import { sendCategoryClick } from "../../helpers/analytics";
import { Category } from "src/api/types";

interface Params {
  categories: Category[];
  categoryLevel: number;
  selectedCategory: Category;
}

const useCategoriesTags = ({ categories, categoryLevel, selectedCategory }: Params): CategoryTagProps[] => {
  const { query } = useRouter();
  const localize = useLocalize();
  const currentCategoryId = query['categoryId'];

  const isSecondLevel = categoryLevel === 2;

  const parentId = selectedCategory.parent_id as string;

  const firstTagCategoryId = isSecondLevel
    ? selectedCategory.id
    : parentId;
  const place = 'category_tags';

  const firstCategoryTag: CategoryTagProps = {
    title: localize('all'),
    href: getCategoryHrefWithSort({
      query,
      categoryId: firstTagCategoryId,
    }),
    isActive: isSecondLevel,
    onClick: () => sendCategoryClick({
      category_id: firstTagCategoryId,
      page: `category_lvl${categoryLevel}`,
      place,
      slider_index: 0,
    }),
  };

  const categoriesToShow: Category[] = (isSecondLevel
    ? selectedCategory.children
    : findCategoryObject(categories, parentId)?.children)
    || [];

  const categoriesTags: CategoryTagProps[] = categoriesToShow.map((c, i) => ({
    title: c.title,
    href: getCategoryHrefWithSort({
      query,
      categoryId: c.id,
    }),
    isActive: currentCategoryId === c.id,
    onClick: () => sendCategoryClick({
      category_id: c.id,
      page: `category_lvl${categoryLevel}`,
      place,
      slider_index: i + 1,
    }),
  }));

  return [firstCategoryTag, ...categoriesTags];
};

export default useCategoriesTags;
