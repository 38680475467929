import { useCallback, useEffect, useRef } from 'react';

/**
 * `useStableFunction` is a custom React Hook that creates a stable function
 * across component re-renders. This is useful when the function is a dependency
 * for other hooks such as `useEffect`, `useMemo`, or `useCallback`.
 *
 * @template Args
 * @template Return
 * @param {(...args: Args) => Return} fn - The original function.
 * @returns {(...args: Args) => Return} - The stable function, which maintains its
 * identity across re-renders and always invokes the latest version of the original function.
 *
 * @example
 * const stableFn = useStableFunction(originalFn);
 */
export function useStableFunction<Args extends unknown[], Return>(
  fn: (...args: Args) => Return,
): (...args: Args) => Return {
  const ref = useRef(fn);

  useEffect(() => {
    ref.current = fn;
  });

  return useCallback((...args) => ref.current(...args), []);
}
