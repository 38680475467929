import StylishBox from 'src/ui-kit/StylishBox/StylishBox';
import { useTheme } from 'src/ui-kit/theme';
import IconFont from '../../ui-kit/IconFont';
import { Social } from './types';

const SocialItem = (props: Social) => {
  const theme = useTheme();

  const { url, type } = props;
  return (
    <StylishBox
      sbs={{
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '40px',
        height: '40px',
        textDecoration: 'none',
        border: `1px solid ${theme.base.color.gray300}`,
        color: theme.base.color.dark,
        borderRadius: '50%',
        backgroundColor: theme.base.color.white,
        transition: '0.2s border-color ease-in-out',
        hover: {
          borderColor: theme.base.color.dark,
        },
      }}
      element='a'
      elementProps={{
        href: url,
        target: "_blank",
        rel: 'noreferrer nofollow',
      }}
      className={`SocialItem SocialItem_${type}`}
    >
      <IconFont name={type} />
    </StylishBox>
  );
};

export default SocialItem;
