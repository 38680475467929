import { useEffect, useRef, useState } from "react";
import useLocalize from "src/services/localize/useLocalize";
import Button from 'src/ui-kit/Button';
import { TruncateStyled } from "./Truncate.styles";

export interface Props {
  content: string;
  lines: number;
}

const Truncate = ({ content, lines }: Props) => {
  const [isTruncated, setIsTruncated] = useState(true);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const withTruncating = isTruncated && isOverflowing;

  const localize = useLocalize();

  useEffect(() => {
    const checkOverflow = () => {
      if (contentRef.current) {
        const element = contentRef.current;
        const lineHeight = parseInt(window.getComputedStyle(element).lineHeight, 10);
        const maxHeight = lineHeight * lines;
        setIsOverflowing(element.scrollHeight > maxHeight);
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [content, lines]);

  const toggleTruncate = () => {
    setIsTruncated(!isTruncated);
  };

  const buttonKey = isTruncated ? 'show-more' : 'show-less';

  return (
    <TruncateStyled
      className='Truncate'
      truncating={withTruncating}
      lines={lines}
    >
      <div
        className='Truncate__content'
        ref={contentRef}
        dangerouslySetInnerHTML={{ __html: content }}
      />
      {isOverflowing && (
        <Button
          onClick={toggleTruncate}
          variant="ghost"
          dataMarker={buttonKey}
          width='auto'
        >
          {localize(`products.${buttonKey}`)}
        </Button>
      )}
    </TruncateStyled>
  );
};

export default Truncate;
