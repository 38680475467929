import cn from 'classnames';
import { useTheme } from 'src/ui-kit/theme';
import { BadgeStyled } from './Badge.styles';

export type BadgeType = 'bundle';

export interface BadgeProps {
  type: BadgeType;
  straightLeft?: boolean;
  text?: string;
  dataMarkerValue?: string;
}

const Badge = (props: BadgeProps) => {
  const {
    type,
    straightLeft,
    dataMarkerValue,
    text,
  } = props;

  const theme = useTheme();

  return (
    <BadgeStyled
      theme={theme}
      className={cn(
        'Badge',
        `Badge_${type}`,
        {
          'Badge_straightLeft': straightLeft,
        })}
      data-testid='productBadge'
      data-marker={dataMarkerValue}
    >
      <span className='Badge__text'>{text}</span>
    </BadgeStyled>
  );
};

export default Badge;
