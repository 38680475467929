import styled from '@emotion/styled';
import { phone } from 'src/styles/media';
import { StyledThemeProps } from 'src/ui-kit/theme/types';

export const ContactInformationStyled = styled('footer', {
  shouldForwardProp: (prop) => prop !== 'theme',
})<StyledThemeProps>(({ theme }) => `
  font-family: ${theme.base.fontFamily.accented};
  max-width: 170px;

  .ContactInformation__item {
    font-size: 16px;
    line-height: 1.5;
    color: ${theme.base.color.dark};
    margin-bottom: 16px;
  }

  .ContactInformation__itemAction {
    color: ${theme.base.color.dark};
    text-decoration: none;
  }

  @media ${phone} {
    max-width: 100%;
  }
`);

