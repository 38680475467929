import AppLinks from "src/components/AppLinks/AppLinks";
import Socials from "src/components/Socials/Socials";
import useLocalize from "src/services/localize/useLocalize";
import { Typography } from '../../../ui-kit/Typography';
import styles from "./FooterSocials.styles";

const FooterSocials = () => {
  const localize = useLocalize();

  return (
    <div className="FooterSocials">
      <div className="FooterSocials__item">
        <Typography variant='text3' sbs={{ mb: '14px' }}>
          {localize('download_app')}
        </Typography>
        <AppLinks buttonColor='gray' grayscaleUnhovered dataMarker="Footer"/>
      </div>

      <div className="FooterSocials__item">
        <Typography variant='text3' sbs={{ mb: '14px' }}>
          {localize('socials_title')}
        </Typography>
        <Socials dataMarker="ContactInformation" />
      </div>

      <style jsx>{styles}</style>
    </div>
  );
};

export default FooterSocials;
