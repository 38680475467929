import { CURRENCY } from "src/data/constants";
import { LocalizeFunc } from 'src/services/localize/useLocalizeService';

const getFilterNamesWithCurrency = (
  name: string,
  localize: LocalizeFunc,
): string => {
  const currencyText = localize(`general.currency.${CURRENCY}`);

  return `${name}, ${currencyText}`;
};

export default getFilterNamesWithCurrency;
