import { Fragment } from "react";
import { RangeFilter } from "src/api/types";
import FilterPrice from "src/ui-kit/FilterPrice/FilterPrice";
import StylishBox from "src/ui-kit/StylishBox/StylishBox";
import useFilterPriceWrap from "../../hooks/useFilterPriceWrap";
import CatalogFiltersMobileArrowHeader from "../CatalogFiltersMobile/CatalogFiltersMobileArrowHeader";
import CatalogFiltersMobileFooter from "../CatalogFiltersMobile/CatalogFiltersMobileFooter";

interface CatalogFiltersMobilePriceProps {
  filter: RangeFilter;
  onBackClick: () => void;
}

const CatalogFiltersMobilePrice = (props: CatalogFiltersMobilePriceProps) => {
  const { filter, onBackClick } = props;

  const { filterPriceProps, onSubmit, filterName, isChanged } = useFilterPriceWrap(filter);

  const handleSubmit = () => {
    onSubmit();

    onBackClick();
  };

  return (
    <Fragment>
      <CatalogFiltersMobileArrowHeader
        onBackClick={onBackClick}
        title={filterName}
      />

      <StylishBox
        sbs={{
          p: '32px 24px',
        }}
      >
        <FilterPrice {...filterPriceProps}  />
      </StylishBox>

      <CatalogFiltersMobileFooter
        onClick={isChanged ? handleSubmit : onBackClick}
        isChanged={isChanged}
      />
    </Fragment>
  );
};

export default CatalogFiltersMobilePrice;
