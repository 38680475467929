import { useTheme } from 'src/ui-kit/theme';
import { SeoTextStyled } from './SeoText.styles';

export interface SeoTextProps {
  content: string;
}

const SeoText = (props: SeoTextProps) => {
  const { content } = props;
  const theme = useTheme();

  return (
    <SeoTextStyled
      theme={theme}
      className="SeoText"
      data-marker="SeoText"
    >
      <div className="SeoText__wrapper">
        <div
          className="SeoText__content"
          dangerouslySetInnerHTML={ { "__html": content } }
        />
      </div>
    </SeoTextStyled>
  );
};

export default SeoText;
