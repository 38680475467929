import { CatalogProductBase } from "src/api/types";
import useLocalize from "src/services/localize/useLocalize";
import getBadgesLocalizations from "../helpers/getBadgesLocalizations";

export const useProductBadges = (product: CatalogProductBase) => {
  const localize = useLocalize();

  const badges = getBadgesLocalizations(
    {
      bundleValue: product.bundle,
    },
    localize,
  );

  return badges;
};

