import { UberProductChain } from "src/api/getProductDetailsData";
import { CartItemCount, RetailChain, ScheduleDeliveryItem } from "src/api/types";
import { Delivery } from "src/modules/delivery";
import { LocalizeFunc } from "src/services/localize/useLocalizeService";
import { getChainsMap } from "../../../utils/chains/getChainsMap";
import { RetailInfoLists } from "../types";
import getRetailInfo from "./getRetailInfo";
import { getUtmQueryString } from "./getUtmQueryString";

interface ProductData {
  ean: string;
  slug: string;
}

interface Params {
  deliveryType: Delivery['type'] | null;
  retailChains: RetailChain[];
  productChains: UberProductChain[];
  cartItemsCounts: CartItemCount[];
  scheduleDeliveryItems: ScheduleDeliveryItem[];
  deliveryAsQueryStringForLink: string;
  productData: ProductData | null;
  language: string;
  localize: LocalizeFunc;
}

export const getRetailInfoListsForProduct = (params: Params) => {
  const {
    productData,
    retailChains,
    productChains,
    deliveryType,
    cartItemsCounts,
    scheduleDeliveryItems,
    deliveryAsQueryStringForLink,
    language,
    localize,
  } = params;

  const retailChainsMap = getChainsMap(retailChains);

  const cartItemsCountsMap: {
    [key: string]: CartItemCount;
  } = cartItemsCounts.reduce((acc, item) => {
    acc[item.chain_id] = item;
    return acc;
  }, {});

  const findTimeWindow = (chainId: string) => {
    const scheduleItem = scheduleDeliveryItems.find(
      (item) => item.chain_id === chainId && !!item.schedule?.length,
    );
    return scheduleItem?.schedule[0].items[0] || null;
  };

  return productChains.reduce<RetailInfoLists>((acc, productChain) => {
    const chainId = productChain.chain_id;
    const retailChain = retailChainsMap[chainId];
    const isAvailable = productChain.available;

    if (!retailChain) {
      return acc;
    }

    //we use the network-specific ean if it exists
    const ean = productChain.ean || productData?.ean;
    const specificRouteForLink = productData
      ? `products/${productData.slug}--${ean}`
      : '';

    const retailInfo = getRetailInfo({
      isAvailable,
      retailChain,
      cartItemsCount: cartItemsCountsMap[chainId]?.count,
      timeWindow: findTimeWindow(chainId),
      deliveryType,
      deliveryAsQueryStringForLink,
      specificRouteForLink,
      language,
      utmQueryString: getUtmQueryString('ubercatalog'),
      price: productChain.price,
      localize,
    });

    if (isAvailable) {
      acc.available.push(retailInfo);
    } else {
      acc.unavailable.push(retailInfo);
    }

    return acc;
  }, { available: [], unavailable: [] });
};
