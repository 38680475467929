import React, { ReactNode, Fragment } from 'react';
import styles from './PageWrapBody.styles';

interface Props {
  phoneTabletModeIsOn?: boolean;
  children: ReactNode;
}

const PageWrapBody = (props: Props) => (
  <Fragment>
    <div
      id={props.phoneTabletModeIsOn
        ? 'PageWrapBody_phoneTabletMode'
        : 'PageWrapBody_desktopMode'
      }
      className='PageWrapBody'
    >
      {props.children}
    </div>
    <style jsx>{styles}</style>
  </Fragment>
);

export default PageWrapBody;
