import Link from 'next/link';
import ScriptLdJson from 'src/components/ScriptLdJson/ScriptLdJson';
import { BASE_URL } from 'src/data/constants';
import { useTheme } from 'src/ui-kit/theme';
import getBreadcrumbsMicroMarking from 'src/utils/marketing/getBreadcrumbsMicroMarking';
import IconFont from '../../ui-kit/IconFont';
import { BreadcrumbsStyled } from './Breadcrumbs.styles';

export interface BreadcrumbsItem {
  title: string;
  url?: string;
  id: string;
}

export interface BreadcrumbsProps {
  items?: BreadcrumbsItem[];
  onBreadcrumbClick?(categoryId: string): void;
}

const Breadcrumbs = (props: BreadcrumbsProps) => {
  const theme = useTheme();

  const { items = [], onBreadcrumbClick } = props;
  const listCount = items.length;

  if (listCount === 0) return null;

  const lastIndex = listCount - 1;

  const handleBreadcrumbClick = (id: string) => {
    if(onBreadcrumbClick) {
      onBreadcrumbClick(id);
    }
  };

  const breadcrumbsMicroMarking = getBreadcrumbsMicroMarking(items, BASE_URL);

  return (
    <BreadcrumbsStyled
      theme={theme}
      className="Breadcrumbs"
      data-marker="Breadcrumbs"
    >
      <ol className="Breadcrumbs__list">
        {items.map((item, index) => {
          const { url, title, id } = item;

          return index === lastIndex ? (
            <li
              key={title}
              className="Breadcrumbs__item breadcrumbs__item--disabled"
              data-marker="Breadcrumbs item"
            >
              <span data-marker="Disabled Breadcrumb">{title}</span>
            </li>
          ) : (
            <li
              key={title}
              className="Breadcrumbs__item"
            >
              {
                !!url && (
                  <Link
                    href={url}
                    data-marker={`Breadcrumb Category ${index}`}
                    onClick={() => handleBreadcrumbClick(id)}
                  >
                    <span
                      className="Breadcrumbs__link"
                    >
                      {title}
                    </span>
                  </Link>
                )
              }
            </li>
          );
        }).reduce((acc, value, index) => {
          // if not the last item we
          // add separator between elements
          if (index < listCount - 1) {
            return [
              ...acc,
              value,
              <li
                aria-hidden
                key={`separator-${index}`}
                className="Breadcrumbs__separator"
              >
                <IconFont name='caret-top' />
              </li>,
            ];
          } else {
            return [...acc, value];
          }
        }, [])}
      </ol>
      <ScriptLdJson body={breadcrumbsMicroMarking} />
    </BreadcrumbsStyled>
  );
};

export default Breadcrumbs;
