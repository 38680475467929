import { ReactNode } from 'react';
import Button from 'src/ui-kit/Button';
import Stack from 'src/ui-kit/Stack/Stack';
import NavButtons from '../NavButtons/NavButtons';
import Responsive from '../Responsive/Responsive';
import Slider from '../SliderV2/Slider';
import { useSlider } from '../SliderV2/helpers/useSlider';

interface MainLink {
  title: string | null;
  href?: string;
  onClick?(): void;
}

interface SliderWrapperProps {
  withNavButtons: boolean;
  titleSlot: ReactNode;
  mainLink?: MainLink;
  children: ReactNode;
  dataMarker?: string;
}

export default function SliderWrapper(props: SliderWrapperProps) {
  const {
    withNavButtons = true,
    children,
    titleSlot,
    dataMarker,
    mainLink,
  } = props;


  const { sliderRef, controls, sliderControls } = useSlider({
    fullWidthScroll: true,
    withSwiperMode: false,
  });

  const mainLinkShouldBeVisible = mainLink && mainLink.href && mainLink.title;
  const controlsShouldBeVisible = withNavButtons && controls;

  const renderMainLinkButton = () => {
    if (mainLinkShouldBeVisible) {
      return (
        <Button
          variant='secondary'
          href={mainLink?.href}
          onClick={mainLink?.onClick}
          dataMarker='View all'
        >
          {mainLink.title}
        </Button>
      );
    }

    return null;
  };

  return (
    <div className='SliderWrapper' data-marker={dataMarker}>
      <Stack
        alignItems='center'
        justifyContent='space-between'
        sbs={{
          mb: {
            general: '24px',
            phone: '14px',
          },
        }}
      >
        <div className='SliderWrapper__title'>
          {titleSlot}
        </div>

        <Responsive displayOn='tabletDesktop'>
          <div className='SliderWrapper__controls'>
            {renderMainLinkButton()}
            {controlsShouldBeVisible && <NavButtons controls={controls} />}
          </div>
        </Responsive>
      </Stack>

      <Slider
        sliderRef={sliderRef}
        controls={sliderControls}
      >
        {children}
      </Slider>

      <Responsive displayOn='phone'>
        {renderMainLinkButton()}
      </Responsive>
    </div>
  );
}
