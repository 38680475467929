import { Category } from "src/api/types";
import { Typography } from "src/ui-kit/Typography";
import CategoryTag from "../CategoryTag/CategoryTag";
import { styles } from "./CategoriesTags.styles";
import useCategoriesTags from "./useCategoriesTags";

export interface Props {
  categories: Category[];
  selectedCategory: Category;
  categoryLevel: number;
  title?: string;
}

const CategoriesTags = (props: Props) => {
  const { title, ...restProps } = props;
  const categoriesTags = useCategoriesTags(restProps);

  return (
    <div className="CategoriesTags" data-marker='Categories'>
      {
        categoriesTags.length > 1 &&
        <div className="CategoriesTags__wrapper">
          {categoriesTags.map((tagProps) => (
            <span
              key={'categoryTag' + tagProps.title}
              className='CategoriesTags__item'
            >
              <CategoryTag {...tagProps} />
            </span>
          ))
          }
        </div>
      }

      {title && (
        <Typography
          element="h1"
          variant='h2'
        >
          {title}
        </Typography>
      )}

      <style jsx>{styles}</style>
    </div >
  );
};


export default CategoriesTags;
