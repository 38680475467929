import { UberProductChain } from 'src/api/getProductDetailsData';
import ScriptLdJson from 'src/components/ScriptLdJson/ScriptLdJson';
import useLocalize from 'src/services/localize/useLocalize';
import StylishBox from 'src/ui-kit/StylishBox/StylishBox';
import { RetailChain, UberCatalogProduct } from '../../../../api/types';
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import CatalogMeta from '../../components/CatalogMeta/CatalogMeta';
import CatalogPageLayout from '../../components/CatalogPageLayout/CatalogPageLayout';
import ProductMainInfo from '../../components/ProductMainInfo/ProductMainInfo';
import ProductPageTabs from '../../components/ProductPageTabs/ProductPageTabs';
import UberCatalogProductsSlider from '../../components/UberCatalogProductsSlider/UberCatalogProductsSlider';
import useProductPageProps from '../../hooks/useProductPageProps';
import { useProductPageSEO } from '../../hooks/useProductPageSEO';

export interface ProductPageProps {
  product: UberCatalogProduct;
  sliders: {
    similar: UberCatalogProduct[];
    recommended: UberCatalogProduct[];
  };
  retailInfoChains: UberProductChain[];
  chains: RetailChain[];
}

const ProductPage = (props: ProductPageProps) => {
  const { product, sliders, retailInfoChains, chains } = props;

  const localize = useLocalize();

  const {
    breadcrumbs,
    tabsProps,
    mainInfoProps,
  } = useProductPageProps(product);

  const {
    title,
    description,
    microMarking,
  } = useProductPageSEO({
    retailInfoChains,
    chains,
    product,
  });

  const { inStock } = mainInfoProps;
  const hasSimilarProducts = sliders.similar.length > 0;
  const hasRecommendedProducts = sliders.recommended.length > 0;

  const similarSlider = (
    <UberCatalogProductsSlider
      title={localize(inStock ? 'product_similar_products' : 'product_not_available')}
      products={sliders.similar}
      place='Product card - similar'
      dataMarker='Similar'
    />
  );

  return (
    <CatalogPageLayout>
      <CatalogMeta
        title={title}
        description={description}
      />
      <StylishBox
        className='ProductPage'
        sbs={{
          pb: '50px',
          px: {
            tablet: '15px',
          },
        }}
      >
        <Breadcrumbs items={breadcrumbs} />

        {!inStock && hasSimilarProducts && (
          <StylishBox sbs={{ mb: '40px' }}>
            {similarSlider}
          </StylishBox>
        )}

        <ProductMainInfo {...mainInfoProps} />
        <ScriptLdJson body={microMarking} />
        <ProductPageTabs {...tabsProps} />

        {inStock && hasSimilarProducts && (
          <StylishBox
            sbs={{
              mb: hasRecommendedProducts ? '56px' : '0',
            }}
          >
            {similarSlider}
          </StylishBox>
        )}

        {hasRecommendedProducts && (
          <UberCatalogProductsSlider
            title={localize('product_buy_together_products')}
            products={sliders.recommended}
            place='Product card - buy with'
            dataMarker='Recommended'
          />
        )}
      </StylishBox>
    </CatalogPageLayout>
  );
};

export default ProductPage;
