import styled from '@emotion/styled';

interface Params {
  lines: number;
  truncating: boolean;
}

const lineHeight = 1.5;

export const TruncateStyled = styled('div', {
  shouldForwardProp: prop => prop !== 'theme' && prop !== 'lines' && prop !== 'truncating',
})<Params>(({ lines, truncating }) => `
  text-align: left;

  .Truncate__content {
    margin: 0 0 10px;
    line-height: ${lineHeight};
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${truncating ? lines : 'none'};
    max-height: ${truncating ? `${lines * lineHeight}em` : 'none'};
  }
`);
