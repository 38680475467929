import StylishBox from "src/ui-kit/StylishBox/StylishBox";
import ProductAbout, { ProductAboutProps } from "../ProductAbout/ProductAbout";
import ProductDescription from "../ProductDescription/ProductDescription";

export type ProductContentProps = ProductAboutProps & {
  description: string;
}

const ProductContent = (props: ProductContentProps) => {
  const {
    description,
    nutritionFacts,
    taxons,
    ingredients,
  } = props;

  return (
    <StylishBox sbs={{
      maxWidth: '850px',
    }}>
      <ProductDescription
        description={description}
      />

      <ProductAbout
        nutritionFacts={nutritionFacts}
        taxons={taxons}
        ingredients={ingredients}
      />
    </StylishBox>
  );
};

export default ProductContent;
