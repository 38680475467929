import { ReactNode } from 'react';
import IconFont from 'src/ui-kit/IconFont';
import { useTheme } from 'src/ui-kit/theme';
import { DropdownStyled } from './Dropdown.styles';

interface Props {
  label: ReactNode;
  children: ReactNode;
  dataMarker?: string;
  position?: 'left' | 'center' | 'right';
  className?: string;
}

const Dropdown = (props: Props) => {
  const { className, children, label, dataMarker = 'dropdown', position = 'center' } = props;
  const theme = useTheme();

  return (
    <DropdownStyled
      theme={theme}
      className={`Dropdown ${className}`}
      data-marker={dataMarker}
      data-testid="dropdown"
    >
      <div className="Dropdown__selected" data-marker={`${dataMarker}-button`}>
        <span className="Dropdown__label">{label}</span>
        <span className="Dropdown__icon">
          <IconFont name='caret-bottom' size='11px'/>
        </span>
      </div>
      <div
        className={`Dropdown__menu Dropdown__menu_${position}`}
        data-marker={`${dataMarker}-menu`}
      >
        <div className="Dropdown__menuInner">
          {children}
        </div>
      </div>
    </DropdownStyled>
  );
};

export default Dropdown;
