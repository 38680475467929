import useLocalize from "src/services/localize/useLocalize";
import { useTheme } from "src/ui-kit/theme";
import { Typography } from "src/ui-kit/Typography";
import { CatalogFiltersMobileCategoriesHeadedStyled } from "./CatalogFiltersMobileCategoriesHeader.styles";

interface CatalogFiltersMobileCategoriesHeaderProps {
  onButtonClick: () => void;
  withButton: boolean;
}

const CatalogFiltersMobileCategoriesHeader = (props: CatalogFiltersMobileCategoriesHeaderProps) => {
  const { withButton, onButtonClick } = props;

  const localize = useLocalize();
  const theme = useTheme();

  return (
    <CatalogFiltersMobileCategoriesHeadedStyled
      theme={theme}
      className='CatalogFiltersMobileCategoriesHeader'
    >
      <Typography variant="h5">
        {localize('general.filters')}
      </Typography>
      {withButton && (
        <button
          type='button'
          onClick={onButtonClick}
          className='CatalogFiltersMobileCategoriesHeader__button'
          data-marker="Clear Button"
        >
          <span>{localize('general.clear-all')}</span>
        </button>
      )}
    </CatalogFiltersMobileCategoriesHeadedStyled>
  );
};

export default CatalogFiltersMobileCategoriesHeader;
