import cn from 'classnames';
import Image from 'next/image';
import { useAppLinks } from '../../utils/appLinks/useAppLinks';
import styles from './AppLinks.styles';

interface Props {
  buttonColor: 'gray' | 'green';
  grayscaleUnhovered?: boolean;
  centerPhone?: boolean;
  dataMarker?: string;
}

const AppLinks = (props: Props) => {
  const { buttonColor, grayscaleUnhovered, centerPhone, dataMarker = 'AppLinks' } = props;

  const appLinks = useAppLinks();

  return (
    <div
      data-marker={dataMarker}
      className={cn(
        'AppLinks', {
        'AppLinks_grayscaleUnhovered': grayscaleUnhovered,
        'AppLinks_centerPhone': centerPhone,
      })}
    >
      {appLinks.android && (
         <a
          href={appLinks.android}
          className='AppLinks__link'
          target='_blank'
          rel='noreferrer nofollow'
          data-marker={`${dataMarker} Android`}
        >
          <Image
            width={136}
            height={44}
            src={`/static/images/google-play-${buttonColor}.svg`}
            alt={`Google play ${buttonColor}`}
          />
        </a>
      )}

      {appLinks.ios && (
        <a
          href={appLinks.ios}
          className='AppLinks__link'
          target='_blank'
          rel='noreferrer nofollow'
          data-marker={`${dataMarker} IOS`}

        >
          <Image
            width={136}
            height={44}
            src={`/static/images/app-store-${buttonColor}.svg`}
            alt={`App store ${buttonColor}`}
          />
        </a>
      )}
      <style jsx>{styles}</style>
    </div>
  );
};

export default AppLinks;
