import { Category } from "src/api/types";
import CategoriesSlider from "../CategoriesSlider/CategoriesSlider";
import CategoriesTags from "../CategoriesTags/CategoriesTags";

export interface CategoryPageContentHeaderProps {
  categoryLevel: number;
  title: string;
  categories: Category[];
  selectedCategory: Category;
}

const CategoryPageContentHeader = (props: CategoryPageContentHeaderProps) => {
  const { categoryLevel, title, selectedCategory, categories } = props;

  if (categoryLevel === 1) {
    return <CategoriesSlider title={title} categories={selectedCategory.children} />;
  }

  return (
    <CategoriesTags
      selectedCategory={selectedCategory}
      categories={categories}
      categoryLevel={categoryLevel}
      title={title}
    />
  );
};

export default CategoryPageContentHeader;
