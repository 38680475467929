import cn from "classnames";
import { Fragment, ReactNode } from "react";
import Footer from "src/components/Footer/Footer";
import { Header } from "src/modules/header";
import { useTheme } from "src/ui-kit/theme";
import ScrollUpButton from "../ScrollUpButton/ScrollUpButton";
import { CatalogPageLayoutStyled } from "./CatalogPageLayout.styles";

interface Props {
  children: ReactNode;
  filterSlot?: ReactNode;
}

const CatalogPageLayout = (props: Props) => {
  const { children, filterSlot } = props;
  const theme = useTheme();

  return (
    <Fragment>
      <Header pageForAnalytics="catalog" withSearch />
      {filterSlot}
      <CatalogPageLayoutStyled
        theme={theme}
        className={cn('CatalogPageLayout', {
          CatalogPageLayout_withFilters: !!filterSlot,
        })}
      >
        <div className="CatalogPageLayout__container">
          {children}
        </div>
      </CatalogPageLayoutStyled>
      <Footer />
      <ScrollUpButton />
    </Fragment >
  );
};



export default CatalogPageLayout;
