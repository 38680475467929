import { IScrollSliderParams } from '../types';
import { getNextPosition } from './getNextPosition';
import { getPrevPosition } from './getPrevPosition';
import { getScrolledSlidesCount } from './getScrolledSlidesCount';
import { swipeContainer } from './scrollUtils';

export function scrollSlider(
  container: HTMLElement | null,
  {
    fullWidthScroll = false,
    callback = () => {},
    slidesPerScroll = 1,
    scrollDirection,
    position = null,
  }: IScrollSliderParams,
): void {
  if (!container) return;

  const { scrollLeft, clientWidth, children } = container;
  const slide = children[0] as HTMLElement | null | undefined;

  if (!slide) return;

  const slideWidth = slide.offsetWidth;
  const args = [
    scrollLeft,
    slideWidth,
    fullWidthScroll
      ? getScrolledSlidesCount(clientWidth, slideWidth)
      : slidesPerScroll,
  ];
  let nextPosition = position;

  if (position === null) {
    if (scrollDirection === 'right')
      nextPosition = getNextPosition(...args);

    if (scrollDirection === 'left')
      nextPosition = getPrevPosition(...args);
  }

  const targetSlide = (
    ((nextPosition !== null && children[nextPosition]) || children[0])
  ) as HTMLElement | null | undefined;

  const nextScrollValue = targetSlide?.offsetLeft;

  if (nextScrollValue !== undefined) {
    const currentPosition = getScrolledSlidesCount(nextScrollValue, slideWidth);

    swipeContainer(container, nextScrollValue);

    callback(currentPosition);
  }
}
