import styled from "@emotion/styled";
import { StyledThemeProps } from "src/ui-kit/theme/types";

export const SwitchLocaleStyled = styled('div', {
  shouldForwardProp: (prop) => prop !== 'theme',
})<StyledThemeProps>(({ theme }) => `
  display: flex;
  font-family: ${theme.base.fontFamily.normal};
  line-height: 24px;
  font-size: 14px;

  .SwitchLocale__link {
    position: relative;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: 0.2s color ease-in-out;
    color: ${theme.base.color.gray600};
  }

  .SwitchLocale__link:not(:last-child) {
    margin-right: 33px;
  }

  .SwitchLocale__link:not(:last-child)::after {
    content: '';
    width: 1px;
    height: 16px;
    position: absolute;
    right: -16px;
    top: 4px;
    background: ${theme.base.color.gray300};
  }

  .SwitchLocale__linkText {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .SwitchLocale__link.active {
    font-weight: 600;
    color: ${theme.base.color.dark};
    pointer-events: none;
  }
`);
