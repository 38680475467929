interface Params {
  lang: string;
  ean: string;
  slug: string;
}

export const getProductUrl = (params: Params) => {
  const {
    lang,
    ean,
    slug,
  } = params;

  return `/${lang}/products/${slug}--${ean}/`;
};
