
interface Props<T = object> {
  body: T;
}

const ScriptLdJson = (props: Props) => {
  const { body } = props;

  return (
    <script
      data-testid="ld-json-script"
      type="application/ld+json"
      dangerouslySetInnerHTML={ { __html: JSON.stringify(body, null, 2) } }
    />
  );
};

export default ScriptLdJson;
