import { ChangeEventHandler, useRef, useState } from "react";
import { Filter, Option } from "src/api/types";
import useUpdateQueryParameter from "../../hooks/useUpdateQueryParameter";
import getFilteredOptionsByString from "../../utils/getFilteredOptionsByString";

const DEFAULT_VISIBLE_FILTERS_COUNT = 5;

export type HandleFilterFunc =  (type: string, name: string) => void;



interface Params {
  filter: Filter;
  hasSearch?: boolean;
  isMobileMode?: boolean;
}

const useCheckList = (params:Params) => {
  const { hasSearch, filter, isMobileMode } = params;

  const [searchWord, setSearchWord] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const inputRef = useRef<HTMLInputElement | null>(null);
  const { updateQueryParameter, selectedQueryFilters } = useUpdateQueryParameter();

  const isSearchMode = searchWord !== '';
  const initialOptions: Option[] = filter.type !=='range' ? filter.options : [];

  const handleChangeSearchWord: ChangeEventHandler<HTMLInputElement> = event => {
    setSearchWord(event.target.value);
  };

  const handleClearSearchWord = () => {
    setSearchWord('');
    inputRef.current?.blur();
  };

  const preparedOptions = isSearchMode
    ? getFilteredOptionsByString(initialOptions, searchWord)
    : initialOptions;
  const optionsCount = preparedOptions.length;
  const shouldNotSliceTheItems = optionsCount <= DEFAULT_VISIBLE_FILTERS_COUNT || isSearchMode || isOpen || isMobileMode;
  const sliceItemsCount = shouldNotSliceTheItems ? optionsCount : DEFAULT_VISIBLE_FILTERS_COUNT;
  const searchBoxIsVisible = initialOptions.length > DEFAULT_VISIBLE_FILTERS_COUNT && hasSearch && filter.key !== 'price';
  const withToggleButton = !isMobileMode && searchBoxIsVisible && !isSearchMode;
  const options = preparedOptions.slice(0, sliceItemsCount);

  return {
    searchWord,
    isOpen,
    setIsOpen,
    searchBoxIsVisible,
    withToggleButton,
    optionsCount,
    handleClearSearchWord,
    handleChangeSearchWord,
    options,
    selectedQueryFilters,
    handleFilterClick: updateQueryParameter,
  };
};

export default useCheckList;
