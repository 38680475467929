import styled from '@emotion/styled';
import { phone, tablet } from 'src/styles/media';
import { StyledThemeProps } from 'src/ui-kit/theme/types';

export const CatalogFiltersMobileCategoriesHeadedStyled = styled('div', {
  shouldForwardProp: (prop) => prop !== 'theme',
})<StyledThemeProps>(({ theme }) => `
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${theme.base.border.v1} ${theme.base.color.gray200};

  .CatalogFiltersMobileCategoriesHeader__button {
    flex-shrink: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    font-size: 14px;
    color: ${theme.base.color.gray600};
  }

  @media ${tablet} {
    padding: 16px 24px;
  }
  @media ${phone} {
    padding: 10px;
  }
`);
