import { UberProductChain } from "src/api/getProductDetailsData";
import { RetailChain } from "src/api/types";
import { LocalizeFunc } from "src/services/localize/useLocalizeService";
import { getChainsTranslationMap } from "src/utils/chains/getChainsTranslationMap";
import { formatPrice } from "src/utils/formatPrice";
import personalizeString from "src/utils/personalize";

interface Params {
  chains: RetailChain[];
  retailInfoChains: UberProductChain[];
  productTitle: string;
  minPrice: number;
  maxPrice: number;
  categoryTitle: string;
  localize: LocalizeFunc;
}

export const getProductPageDescription = (params: Params) => {
  const {
    chains,
    retailInfoChains,
    productTitle,
    maxPrice,
    minPrice,
    categoryTitle,
    localize,
  } = params;
  const chainsTranslations = getChainsTranslationMap(chains);

  const availableStoresString = retailInfoChains
    .filter((item) => item.available)
    .map((item) => chainsTranslations[item.chain_id])
    .join(', ');

  const availableStoresExists = availableStoresString.length > 0;

  if (availableStoresExists) {
    return personalizeString(
      localize('products.meta.description'),
      [
        productTitle,
        availableStoresString,
        formatPrice(minPrice),
        formatPrice(maxPrice),
      ],
    );
  }

  return personalizeString(
    localize('products.meta.description.out_stock'),
    [
      productTitle,
      formatPrice(minPrice),
      categoryTitle,
    ],
  );
};
