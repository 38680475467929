interface Params {
  categoryId: string;
  lang: string;
  query?: string | null;
}

export const getCategoriesUrl = (params: Params) => {
  const {
    categoryId,
    lang,
    query,
  } = params;
  const queryForUrl = query || '';
  return `/${lang}/categories/${categoryId}/${queryForUrl}`;
};
