export function getPrevPosition(...args: number[]): number;
export function getPrevPosition(
  scrollLeft: number,
  slideWidth: number,
  slidesPerScroll?: number,
): number {
  const currPosition: number = scrollLeft / slideWidth;
  const perfPosition: number = Math.floor(currPosition);
  const addPosition = slidesPerScroll ? slidesPerScroll - 1 : 0;

  if (currPosition - perfPosition > 0.05) return perfPosition - addPosition;

  return perfPosition - addPosition - 1;
}
