import useLocalize from 'src/services/localize/useLocalize';
import IconFont from 'src/ui-kit/IconFont';
import StylishBox from 'src/ui-kit/StylishBox/StylishBox';
import { Typography } from 'src/ui-kit/Typography';

const AccountButtonContent = () => {
  const localize = useLocalize();

  return (
    <Typography
      element='span'
      variant='text6'
      sbs={{
        lineHeight: '1',
        display: {
          phone: 'inline-block',
        },
        width: {
          phone: '20px',
        },
        height: {
          phone: '20px',
        },
      }}
    >
      <IconFont
        name='user'
        size={{
          general: '14px',
          phone: '18px',
        }}
      />
      <StylishBox
        element='span'
        sbs={{
          display: {
            phone: 'none',
          },
          ml: '7px',
        }}
      >
        {localize('user.account')}
      </StylishBox>
    </Typography>
  );
};

export default AccountButtonContent;
