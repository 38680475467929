import useLocalize from "src/services/localize/useLocalize";
import StylishBox from "src/ui-kit/StylishBox/StylishBox";
import { Typography } from "src/ui-kit/Typography";
import CatalogPageLayout from "../CatalogPageLayout/CatalogPageLayout";
import PageWrap from "../PageLayout/PageWrap/PageWrap";

export const searchNotFoundReasons = [
  'error.search.typo',
  'error.search.another-text',
  'error.search.general-text',
];

interface Props {
  searchString: string;
}

const SearchEmpty = ({ searchString }: Props) => {
  const localize = useLocalize();

  return (
    <CatalogPageLayout>
      <PageWrap>
        <Typography
          variant="h5"
          sbs={{ fontWeight: '400' }}
        >
          {localize('search.result.nothing-found')}
          <StylishBox
            sbs={{ fontWeight: '600' }}
            element="span"
          >
            {` «${searchString}»`}
          </StylishBox>

          <StylishBox
            element="ul"
            sbs={{
              mt: '40px',
              pl: '25px',
              listStyle: 'disc',
            }}
          >
            {searchNotFoundReasons.map((reason) => (
              <Typography
                key={reason}
                element="li"
              >
                {localize(reason)}
              </Typography>
            ))}
          </StylishBox>
        </Typography>
      </PageWrap>
    </CatalogPageLayout>
  );
};

export default SearchEmpty;
