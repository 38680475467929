import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import makeSearchString from 'src/utils/system/makeSearchString';
import filterSearchParamsArr from '../utils/filterSearchParamsArr';
import getQueryWithMergedFilterParams from '../utils/getQueryWithMergedFilterParams';
import getSelectedFiltersFromQuery, { createFilterParam } from '../utils/getSelectedFiltersFromQuery';
import toggleQueryFilter, { NOT_MULTI_FILTER_PARAMS } from '../utils/toggleQueryFilter';

const firstLevelFilterSegmentIndex = 4;

// Хук для обновления параметров запроса
const useUpdateQueryParameter = () => {
  const router = useRouter();
  const { push, query, asPath, pathname } = router;
  const { page, ...restQuery } = query;

  const [path, params] = asPath.split('?');

  const queryWithMergedFilterParams = getQueryWithMergedFilterParams(query);
  const selectedQueryFilters = getSelectedFiltersFromQuery(queryWithMergedFilterParams);

  // Функция для обновления параметра запроса
  const toggleInQueryString = (type: string, name: string = '') => {
    const newQuery = toggleQueryFilter(type, name, restQuery);
    const searchParams = makeSearchString(newQuery);

    push(`${path}${searchParams}`);
  };

   // Функция для добавления фильтра в сегмент URL
   const addFilterSegment = (type: string, name: string) => {
    const newFilterParam = createFilterParam(type, name, '_');
    const newQuery = { ...restQuery, firstLevelFilter: newFilterParam };
    const newParams = makeSearchString(newQuery);
    const newAsPath = `${path}${newFilterParam}/${newParams ? newParams : ''}`;

    push(newAsPath);
  };

   // Функция для обновления сегмента фильтра в зависимости от параметров запроса
   const updateSegmentWithQuery = () => {
    const searchParamsArr = params ? params.split('&') : [];
    const filterParamsArr = filterSearchParamsArr(searchParamsArr, NOT_MULTI_FILTER_PARAMS);

    if (filterParamsArr.length > 0) {
      updateSegmentWithAvailableFilters(filterParamsArr);
    } else {
      clearFilterSegment();
    }
  };

  // Функция для обновления сегмента фильтра с доступными фильтрами
  const updateSegmentWithAvailableFilters = (filterParamsArr: string[]) => {
    // Есть фильтры, которые можно использовать в сегменте
    const firstFilterParam = filterParamsArr[0];
    const [newType, newName] = decodeURIComponent(firstFilterParam).split('=');
    const newQuery = toggleQueryFilter(newType, newName, restQuery);
    newQuery.firstLevelFilter = firstFilterParam.replace('=', '_');
    const newSearchParams = makeSearchString(newQuery);

    const segments = path.split('/');
    segments[firstLevelFilterSegmentIndex] = newQuery.firstLevelFilter;
    const newPath = segments.join('/');

    push(`${newPath}${newSearchParams ? newSearchParams : ''}`);
  };

   // Функция для очистки сегмента фильтра
   const clearFilterSegment = () => {
    const newQuery = { ...restQuery };
    delete newQuery.firstLevelFilter;

    const segments = path.split('/');
    const newPath = segments.slice(0, firstLevelFilterSegmentIndex).join('/');
    const newSearchParams = makeSearchString(newQuery);

    router.push(`${newPath}/${newSearchParams ? newSearchParams : ''}`);
  };

  // Функция для обновления фильтра в сегменте URL
  function toggleWithFilterSegmentOfURL(
    type: string,
    name: string,
  ) {
    const firstLevelFilter = Array.isArray(restQuery.firstLevelFilter) ? '' : restQuery.firstLevelFilter;

    if (!firstLevelFilter) {
      // Если сегмента фильтра нет, добавляем его
      addFilterSegment(type, name);
    } else {
      // Если сегмент фильтра присутствует, обновляем его
      const convertedFromSegmentToParams = firstLevelFilter.replace('_', '=');
      const isNewFilterEqualSegmentFilter = convertedFromSegmentToParams === `${type}=${name}`;

      if (!isNewFilterEqualSegmentFilter) {
        // Если фильтр изменился
        const newQuery = toggleQueryFilter(type, name, restQuery);
        const searchParams = makeSearchString(newQuery);

        push(`${path}${searchParams ? searchParams : ''}`);
      } else {
        updateSegmentWithQuery();
      }
    }
  }

  // Функция для очистки всех параметров запроса, кроме 'lang'
  // так же опциональных параметров 'categoryId' и 'q'
  const clearAllQueryParameters = () => {
    const { lang, categoryId, q, firstLevelFilter } = restQuery;

    const newQuery: ParsedUrlQuery = { lang };

    if (categoryId) {
      newQuery.categoryId = categoryId;
    }

    if (q) {
      newQuery.q = q;
    }

    const newPathname = firstLevelFilter ? pathname.replace('/[firstLevelFilter]', '') : pathname;

    router.push({
      pathname: newPathname,
      query: newQuery,
    });
  };

  // Функция для удаления последнего фильтра
  const removeLastFilter = () => {
    const searchParamsArr = params ? params.split('&') : [];
    const filterParams = filterSearchParamsArr(searchParamsArr);
    const lastFilterParam = filterParams[filterParams.length - 1];
    const [type] = decodeURIComponent(lastFilterParam).split('=');

    updateQueryParameter(type);
  };

  // Функция для обновления параметра запроса в зависимости от текущего сегмента URL
  const updateQueryParameter = (key: string, value: string = '') => {
    const segments = path.split('/');

    if (segments[2] === 'categories' && key !== 'price') {
      // Обработка для сегмента категорий
      toggleWithFilterSegmentOfURL(key, value);
    } else {
      // Обработка для других сегментов
      toggleInQueryString(key, value);
    }
  };

  return {
    selectedQueryFilters,
    removeLastFilter,
    updateQueryParameter,
    clearAllQueryParameters,
  };
};

export default useUpdateQueryParameter;
