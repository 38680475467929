import React, { CSSProperties, ReactNode } from 'react';
import GeneralModal from '../GeneralModal/GeneralModal';

interface GeneralDrawerModalProps {
  children: ReactNode;
  onClose(): void;
  isOpen: boolean;
}

const contentStyles: CSSProperties = {
  height: '100%',
  borderRadius: 0,
  border: 'none',
  marginRight: 'auto',
  position: 'relative',
};

const GeneralDrawerModal = (props: GeneralDrawerModalProps) => {
  const { onClose, children, isOpen } = props;

  return (
    <GeneralModal
      isOpen={isOpen}
      onClose={onClose}
      contentStyles={contentStyles}
    >
      {children}
    </GeneralModal>
  );
};

export default GeneralDrawerModal;
