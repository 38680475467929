import ReactPaginate, { ReactPaginateProps } from 'react-paginate';
import { useTheme } from 'src/ui-kit/theme';
import IconFont from '../../../../ui-kit/IconFont';
import { PaginationStyled } from './Pagination.styles';

interface Props {
  paginationOptions: Partial<ReactPaginateProps>;
}

export const Pagination = ({ paginationOptions }: Props) => {
  const theme = useTheme();

  return (
    <PaginationStyled
      theme={theme}
    >
      <ReactPaginate
        pageCount={0}
        breakLabel='...'
        breakClassName='Pagination__break'
        containerClassName='Pagination'
        pageLinkClassName='Pagination__item'
        activeLinkClassName='Pagination__item_active'
        previousLabel={<IconFont name='arrow-left' />}
        nextLabel={<IconFont name='arrow-right' />}
        previousClassName='Pagination__direction'
        previousLinkClassName='Pagination__directionLink'
        nextClassName='Pagination__direction'
        nextLinkClassName='Pagination__directionLink'
        disabledClassName='Pagination__direction_disabled'
        {...paginationOptions}
      />
    </PaginationStyled>
  );
};

export default Pagination;
