import css from 'styled-jsx/css';
import { desktop, phone_tablet } from '../../styles/media';

const styles = css`
  .ProductsSlider__item {
    flex-shrink: 0;
    border-color: #efefef;
    border-width: 1px 1px 1px 1px;
    border-style: solid;
  }
  .ProductsSlider__item {
    width: 20%;
  }
  @media ${desktop} {
    /* Products hovering */
    :global(.ProductsSlider .Slider__viewport:hover .ProductsSlider__item) {
      opacity: 0.5;
      transition: 0.2s opacity ease-in-out;
    }
    :global(.ProductsSlider .Slider__viewport .ProductsSlider__item:hover) {
      opacity: 1;
    }
  }

  .ProductsSlider__info {
    height: 40px;
    position: relative;
    margin-bottom: 20px;
  }

  .ProductsSlider__titleShape {
    width: 124px;
    height: 40px;
    margin: 0 auto;
  }

  .ProductsSlider__actions {
    display: none;
  }

  @media ${desktop} {
    .ProductsSlider__actions {
      display: flex;
      align-self: center;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  @media ${phone_tablet} {
    .ProductsSlider__item {
      width: 228px;
    }
  }
`;

export default styles;
