import Head from 'next/head';
import useCanonicalHref from 'src/utils/useCanonicalHref';

export interface CatalogMetaProps {
  title?: string;
  description?: string;
  isClosedFromIndexing?: boolean;
}

const CatalogMeta = (props: CatalogMetaProps) => {
  const { description, title, isClosedFromIndexing } = props;
  const canonicalHref = useCanonicalHref();

  return (
    <Head  data-testid='head'>
      <link rel="canonical" href={canonicalHref} />

      {isClosedFromIndexing &&
        <meta name="robots" content="noindex, nofollow" key='meta-robots' />
      }

      {title && (
        <title key='title'>{title}</title>
      )}

      {description && (
        <meta name='description' content={description} key='meta-description' />
      )}
    </Head>
  );
};

export default CatalogMeta;
