import { Category } from "src/api/types";
import { useServices } from "..";

const useCategories = (): Category[] => {
  const services = useServices();

  if (!services) {
    throw new Error('ServicesContext is used without provider in useCategories');
  }

  return services.categories;
};

export default useCategories;
