import { Children, FC, Fragment, ReactNode } from "react";
import { RectShape } from 'react-placeholder/lib/placeholders';
import NavButtons from "../NavButtons/NavButtons";
import Slider from "../SliderV2";
import { useSlider } from "../SliderV2/helpers/useSlider";
import styles from './ProductsSlider.styles';
import ProductsSliderPlaceholder from "./components/ProductsSliderPlaceholder";

interface ProductsSliderProps {
  children: ReactNode;
  isFetching: boolean;
  titleSlot: ReactNode;
  buttonSlot?: ReactNode;
  dataMarker?: string;
}

const ProductsSlider: FC<ProductsSliderProps> = (props) => {
  const { children, isFetching, titleSlot, buttonSlot, dataMarker } = props;

  const { sliderRef, controls, sliderControls } = useSlider({
    fullWidthScroll: true,
    withSwiperMode: false,
  });

  return (
    <div className="ProductsSlider">
      <div className="ProductsSlider__info" data-marker='Product slider header'>
        {isFetching ? (
          <div className="ProductsSlider__titleShape">
            <RectShape color="#eee" className="ProductsSliderPlaceholder__shape" />
          </div>
        ) : (
          <Fragment>
            {titleSlot}

            <div className="ProductsSlider__actions">
              {buttonSlot}
              {controls && <NavButtons dataMarker='Navigation buttons' controls={controls} />}
            </div>
          </Fragment>
        )}
      </div>

      <Slider
        sliderRef={sliderRef}
        controls={sliderControls}
        dataMarker={dataMarker}
      >
        {isFetching
          ? [1, 2, 3, 4, 5].map(i => (
            <div key={i} className="ProductsSlider__item">
              <ProductsSliderPlaceholder />
            </div>
          ))
          : Children.map(children, (child, index) => (
            <div key={index} className="ProductsSlider__item">
              {child}
            </div>
          ))
        }
      </Slider>

      <style jsx>{styles}</style>
    </div>
  );

};

export default ProductsSlider;
