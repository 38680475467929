import StylishBox from 'src/ui-kit/StylishBox/StylishBox';
import { BadgesLocalizationsType } from '../../helpers/getBadgesLocalizations';
import Badge, { BadgeProps } from './Badge';


interface BadgesProps extends Partial<BadgesLocalizationsType> {
  straightLeft?: boolean;
  withOpacity?: boolean;
}

const Badges = (props: BadgesProps) => {
  const {
    straightLeft,
    bundle,
    withOpacity,
  } = props;

  const commonProps: Partial<BadgeProps> = {
    straightLeft,
  };

  return (
    <StylishBox
      sbs={{
        display: 'flex',
        flexDirection: 'column',
        minWidth: '48px',
        opacity: withOpacity ? '0.5' : '1',
        position: 'absolute',
        top: '12px',
        left: '0',
      }}
    >
      {bundle && (
        <Badge
          text={bundle}
          type='bundle'
          dataMarkerValue='Bundle'
          {...commonProps}
        />
      )}
    </StylishBox>
  );
};

export default Badges;
