import { Fragment } from 'react';
import { Filter } from 'src/api/types';
import StylishBox from 'src/ui-kit/StylishBox/StylishBox';
import { ActiveFilter } from '../../utils/getActiveFiltersObjects';
import CatalogFiltersMobileArrowHeader from '../CatalogFiltersMobile/CatalogFiltersMobileArrowHeader';
import CheckList from '../CheckList/CheckList';

interface CatalogFiltersMobileOptionsProps {
  selectedFilterType: Filter;
  activeFilters: ActiveFilter[];
  closeCategory(): void;
  deleteFilters: (type: string) => void;
}

const CatalogFiltersMobileOptions = (props: CatalogFiltersMobileOptionsProps) => {
  const {
    activeFilters,
    selectedFilterType,
    closeCategory,
    deleteFilters,
  } = props;

  const filterIndexInActiveList = activeFilters.findIndex(item => (
    item.type === selectedFilterType.key
  ));

  const isVisibleClearButton = filterIndexInActiveList !== -1;

  return (
    <Fragment>
      <CatalogFiltersMobileArrowHeader
        onBackClick={closeCategory}
        onClearClick={() => deleteFilters(selectedFilterType.key)}
        isVisibleClearButton={isVisibleClearButton}
        title={selectedFilterType.name}
      />
      <StylishBox
        sbs={{
          overflowY: 'auto',
        }}
      >
        <CheckList
          isMobileMode
          filter={selectedFilterType}
          hasSearch
        />
      </StylishBox>
    </Fragment>
  );
};

export default CatalogFiltersMobileOptions;
