import { CURRENCY } from "src/data/constants";
import { LocalizeFunc } from "src/services/localize/useLocalizeService";
import { ActiveFilter } from "./getActiveFiltersObjects";
import getParsedFilterPriceValue from "./getParsedFilterPriceValue";
import getPriceAndCurrencyText from "./getPriceAndCurrencyText";

const getFilterOptionLabel = (filter: ActiveFilter, localize: LocalizeFunc) => {
  return filter.type === 'price' && filter.price
    ? getPriceAndCurrencyText({
        price: getParsedFilterPriceValue(filter.price),
        localize,
        currency: CURRENCY,
      })
    : filter.option?.name;
};

export default getFilterOptionLabel;
