import cn from 'classnames';
import { ReactNode } from 'react';
import styles from './FlexList.styles';

interface FlexListProps<T extends {id: string}> {
  size: 'large' | 'medium' | 'small';
  data: T[];
  renderItem(item: T): ReactNode;
}

function FlexList<T extends {id: string}>(props: FlexListProps<T>) {
  const { size, data, renderItem } = props;

  return (
    <div className={cn('FlexList', {
      'FlexList_small': size === 'small',
      'FlexList_medium': size === 'medium',
      'FlexList_large': size === 'large',
    })}>
      {
        data.map((item) => (
          <div key={item.id} className="FlexList__item">
            {renderItem(item)}
          </div>
        ))
      }
      <style jsx>{styles}</style>
    </div>
  );
}

export default FlexList;
