import classNames from 'classnames';
import { usePathname } from 'next/navigation';
import { useTheme } from 'src/ui-kit/theme';
import savePreferredLanguage from 'src/utils/js-helpers/savePreferredLanguage';
import { LANGUAGES } from '../../../../data/constants';
import useLang from '../../../../services/language/useLang';
import { useLocalize } from '../../../../services/localize/useLocalize';
import { SwitchLocaleStyled } from './SwitchLocale.styles';

const SwitchLocale = () => {
  const currentLanguage = useLang();
  const theme = useTheme();
  const pathname = usePathname();
  const pathRegExp = new RegExp(`^/${currentLanguage}`);

  const localize = useLocalize();

  return (
    <SwitchLocaleStyled
      className='SwitchLocale'
      theme={theme}
    >
      {
        LANGUAGES.map(language => {
          const newLangSegment = `/${language}`;
          return (
            <a
              key={language}
              href={pathname?.replace(pathRegExp, newLangSegment) || newLangSegment}
              onClick={() => savePreferredLanguage(language)}
              className={
                classNames(
                  'SwitchLocale__link',
                  {
                    active: language === currentLanguage,
                  },
                )
              }
            >
              <span className='SwitchLocale__linkText'>{localize(`lang_${language}`)}</span>
            </a>
          );
        })
      }
    </SwitchLocaleStyled>
  );
};

export default SwitchLocale;
