import cn from 'classnames';
import { Fragment } from 'react';
import { useTheme } from 'src/ui-kit/theme';
import { useLocalize } from '../../services/localize/useLocalize';
import { formatPrice } from '../../utils/formatPrice';
import { PricesRangeStyled } from './PricesRange.styles';

interface Props {
  startPrice: number;
  endPrice?: number;
  currency: string;
  variant?: 'default' | 'small';
}

const PriceRange = (props: Props) => {
  const { startPrice, endPrice, currency, variant = 'default' } = props;
  const localize = useLocalize();
  const theme = useTheme();

  const currencyText = localize(`general.currency.${currency}`);
  const pricesAreEqual = endPrice == startPrice;
  const separator = variant === 'small' ? '-' : '—';

  return (
    <PricesRangeStyled
      theme={theme}
      className={cn('PricesRange', {
        PricesRange_small: variant === 'small',
      })}
    >
      <span className='PricesRange__start'>
        {formatPrice(startPrice)}
      </span>
      <span className='PricesRange__currency'>
        {currencyText}
      </span>
      {endPrice && !pricesAreEqual &&
        <Fragment>
          <span className='PricesRange__end'>{` ${separator} ${formatPrice(endPrice)}`}</span>
          <span className='PricesRange__currency'>
            {currencyText}
          </span>
        </Fragment>
      }
    </PricesRangeStyled>
  );
};

export default PriceRange;
