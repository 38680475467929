import { useTheme } from 'src/ui-kit/theme';
import { useLocalize } from '../../services/localize/useLocalize';
import { Typography } from '../../ui-kit/Typography';
import { ContactInformationStyled } from './ContactInformation.styles';

const ContactInformation = () => {
  const theme = useTheme();
  const localize = useLocalize();

  return (
    <ContactInformationStyled
      theme={theme}
      className="ContactInformation"
    >
      <Typography variant='text3' sbs={{ mb: '12px' }}>
        {localize('pages_contacts')}
      </Typography>
      <div className="ContactInformation__item">
        <a className="ContactInformation__itemAction"
          href={`mailto:${localize('contact_center.info_email')}`}>
          {localize('contact_center.info_email')}
        </a>
      </div>
      <Typography dataMarker='Footer Address'>
        {localize('company_adress')}
      </Typography>
    </ContactInformationStyled>
  );
};

export default ContactInformation;
