import { ISetBoundaryOptions } from '../types';

export function setBoundaryPosition(
  container: HTMLElement,
  { onBegin, onEnd, isBegin, isEnd }: ISetBoundaryOptions,
): void {
  const { scrollLeft, clientWidth, scrollWidth } = container;

  if (scrollLeft === 0 && !isBegin) onBegin(true);
  if (scrollLeft > 0 && isBegin) onBegin(false);
  if (scrollLeft >= scrollWidth - clientWidth - 2 && !isEnd) onEnd(true);
  if (scrollLeft < scrollWidth - clientWidth - 2 && isEnd) onEnd(false);
}
