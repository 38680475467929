import cn from 'classnames';
import { useTheme } from 'src/ui-kit/theme';
import savePreferredLanguage from 'src/utils/js-helpers/savePreferredLanguage';
import { LANGUAGES } from '../../../../data/constants';
import useLang from '../../../../services/language/useLang';
import { useLocalize } from '../../../../services/localize/useLocalize';
import { DesktopSwitchLocaleStyled } from './DesktopSwitchLocale.styles';
import { usePathname } from 'next/navigation';

const DesktopSwitchLocale = () => {
  const currentLanguage = useLang();
  const theme = useTheme();

  const pathname = usePathname();
  const pathRegExp = new RegExp(`^/${currentLanguage}`);

  const localize = useLocalize();

  return (
    <DesktopSwitchLocaleStyled
      label={localize(`lang_${currentLanguage}`)}
      dataMarker="lang"
      theme={theme}
    >
      {LANGUAGES.map(language => {
        const newLangSegment = `/${language}`;
        return (
          <div
            key={language}
            className="DesktopSwitchLocale__menuItem"
          >
            <a
              href={pathname?.replace(pathRegExp, newLangSegment) || newLangSegment}
              onClick={() => savePreferredLanguage(language)}
              className={cn('DesktopSwitchLocale__menuLink', {
                DesktopSwitchLocale__menuLinkActive: language === currentLanguage,
              })}
              data-marker={`lang-link-${language}`}
            >
              <span className='DesktopSwitchLocale__linkText'>
                {localize(`lang_${language}`)}
              </span>
            </a>
          </div>
        );
      })}
    </DesktopSwitchLocaleStyled>
  );
};

export default DesktopSwitchLocale;
