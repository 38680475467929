import { useMediaQuery } from "react-responsive";
import Truncate from "src/components/Truncate/Truncate";
import { desktop } from "src/styles/media";
import StylishBox from "src/ui-kit/StylishBox/StylishBox";

interface Props {
  description: string;
}

const ProductDescription = (props: Props) => {
  const {
    description,
  } = props;

  const isDesktop = useMediaQuery({ query: desktop });
  const lines = isDesktop ? 50 : 4;

  if (description.length === 0) {
    return null;
  }

  return (
    <StylishBox sbs={{
      mb: '24px',
    }}>
      <Truncate content={description} lines={lines} />
    </StylishBox>
  );
};

export default ProductDescription;
