import styled from '@emotion/styled';
import { desktop, phone, tablet } from 'src/styles/media';
import { StyledThemeProps } from 'src/ui-kit/theme/types';

export const ProductTilePlaceholderStyled = styled('div', {
  shouldForwardProp: (prop) => prop !== 'theme',
})<StyledThemeProps>(({ theme }) => `
  margin-bottom: 60px;
  display: flex;
  flex-wrap: wrap;

  .ProductTilePlaceholder__item {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
    border-style: solid;
    background-color: ${theme.base.color.white};
    border-width: 0 1px 1px 0;
    border-color: ${theme.base.color.gray200};
  }
  .ProductTilePlaceholder__image {
    margin-bottom: 16px;
  }
  .ProductTilePlaceholder__button {
    height: 26px;
    margin-bottom: 12px;
  }
  @media ${desktop} {
    .ProductTilePlaceholder__item {
      height: 327px;
    }
    .ProductTilePlaceholder__image {
      height: 180px;
    }
  }
  @media ${tablet} {
    .ProductTilePlaceholder__item {
      height: 300px;
    }
    .ProductTilePlaceholder__image {
      height: 144px;
    }
  }
  @media ${phone} {
    margin: 0 0 40px;

    .ProductTilePlaceholder__item:nth-last-child(-n+2) {
      display: none;
    }

    .ProductTilePlaceholder__item {
      width: 50%;
      height: 294px;
    }
    .ProductTilePlaceholder__image {
      height: 140px;
    }
  }
`);
