import { SearchResponseDto } from "src/api/types";
import Responsive from "src/components/Responsive";
import { CatalogFilters, CatalogFiltersEmpty, CatalogFiltersMobile } from "src/modules/filters";
import useLocalize from "src/services/localize/useLocalize";
import PagePreloader from "src/ui-kit/PagePreloader/PagePreloader";
import { Typography } from "src/ui-kit/Typography";
import Breadcrumbs from "../../../../components/Breadcrumbs/Breadcrumbs";
import CatalogPageLayout from "../../components/CatalogPageLayout/CatalogPageLayout";
import PageWrap from "../../components/PageLayout/PageWrap/PageWrap";
import PageWrapAside from "../../components/PageLayout/PageWrapAside/PageWrapAside";
import PageWrapBody from "../../components/PageLayout/PageWrapBody/PageWrapBody";
import ProductBox from "../../components/ProductBox/ProductBox";
import SearchEmpty from "../../components/SearchEmpty/SearchEmpty";
import SearchMeta from '../../components/SearchMeta/SearchMeta';
import useSearchPageProps from "../../hooks/useSearchPageProps";

export interface SearchPageProps {
  searchData: SearchResponseDto;
}

export const searchErrorList = [
  'error.search.typo',
  'error.search.another-text',
  'error.search.general-text',
];

const SearchPage = (props: SearchPageProps) => {
  const { searchData } = props;


  const localize = useLocalize();
  const {
    breadcrumbsProps,
    productBoxProps,
    searchString,
    searchMetaProps,
    loading,
    isVisibleEmptySearchState,
    isVisibleEmptyFilterState,
  } = useSearchPageProps(searchData);

  const { activeFilters } = productBoxProps;
  const { filters } = searchData;

  if (isVisibleEmptySearchState) {
    return (
      <SearchEmpty searchString={searchString} />
    );
  }

  return (
    <CatalogPageLayout
      filterSlot={
        <Responsive displayOn="phoneTablet">
          <CatalogFiltersMobile
            filters={filters}
            activeFilters={activeFilters}
          />
        </Responsive>
      }
    >
      <SearchMeta {...searchMetaProps} />
      <Breadcrumbs {...breadcrumbsProps} />
      <Typography
        variant="h4"
        sbs={{ mb: '24px' }}
      >
        {`${localize('search_results')} “${searchString}”`}
      </Typography>
      <PageWrap>
        <PagePreloader loading={loading} />

        <PageWrapAside>
          <Responsive displayOn="desktop">
            <CatalogFilters filters={filters} />
          </Responsive>
        </PageWrapAside>
        <PageWrapBody>


          {isVisibleEmptyFilterState ? (
            <CatalogFiltersEmpty activeFilters={activeFilters} />
          ) : (
            <ProductBox {...productBoxProps} />
          )}
        </PageWrapBody>
      </PageWrap>
    </CatalogPageLayout>
  );
};

export default SearchPage;
