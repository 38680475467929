import { useContext } from 'react';
import { RetailsInfoContextValue } from '../types';
import RetailsInfoContext from './RetailsInfoContext';

const useRetailsInfoContext = (): RetailsInfoContextValue => {
  const ctxValue = useContext(RetailsInfoContext);

  if (ctxValue === null) {
    throw new Error('Using RetailsInfoContext out of provider');
  }

  return ctxValue;
};

export default useRetailsInfoContext;
