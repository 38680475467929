import { AddressManagementBar, AddressManagementBarProps } from "src/modules/address-management";
import useLocalize from "src/services/localize/useLocalize";
import StylishBox from "src/ui-kit/StylishBox/StylishBox";
import { Typography } from "src/ui-kit/Typography";
import { useProductPageExperiment } from "../../hooks/useProductPageExperiment";
import { ProductRetailsInfo, ProductRetailsInfoProps } from "src/modules/retails";

export interface WhereOrderProps {
  addressManagementProps: AddressManagementBarProps;
  retailsInfoProps: ProductRetailsInfoProps;
}

const WhereOrder = (props: WhereOrderProps) => {
  const { addressManagementProps, retailsInfoProps } = props;

  const localize = useLocalize();
  const { experimentIsActive } = useProductPageExperiment();

  return (
    <div className="WhereOrder">
      {!experimentIsActive && (
        <Typography
          sbs={{
            mb: '13px',
          }}
          variant='text6'
          color='gray500'
        >
          {localize('where_order_description')}
        </Typography>
      )}

      <StylishBox sbs={{ mb: '20px' }}>
        <AddressManagementBar
          withIconAnimation
          {...addressManagementProps}
        />

        <ProductRetailsInfo {...retailsInfoProps} />
      </StylishBox>
    </div>
  );
};

export default WhereOrder;
