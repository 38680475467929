import { desktop, phone, tablet } from 'src/styles/media';
import css from 'styled-jsx/css';

const styles = css`
  .SidebarMenu__button {
    width: 24px;
    height: 24px;
    border: none;
    cursor: pointer;
    outline: none;
    background: url('/static/images/burger.svg') center center no-repeat;
    display: block;
  }

  @media ${desktop} {
    .SidebarMenu__address {
      padding-left: 32px;
      padding-right: 32px;
    }
  }

  @media ${tablet} {
    .SidebarMenu__address {
      padding-left: 32px;
      padding-right: 32px;
    }
  }

  @media ${phone} {
    .SidebarMenu__address {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
`;

export default styles;
