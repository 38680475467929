import { useRef, useState } from "react";
import { UberCatalogProduct } from "src/api/types";
import { ProductMainInfoProps } from "../components/ProductMainInfo/ProductMainInfo";
import { ProductPageTabsProps } from "../components/ProductPageTabs/ProductPageTabs";
import { sendAddToCartClick } from "../helpers/analytics";
import useBreadcrumbsCatalogItems from "./useBreadcrumbsCatalogItems";
import { useProductBadges } from "./useProductBadges";
import useWhereOrderProps from "./useWhereOrderProps";

const useProductPageProps = (product: UberCatalogProduct) => {
  const {
    title,
    unit,
    volume,
    weight,
    gallery,
    category_id,
    taxons,
    ingredients,
    nutrition_facts,
    description,
    in_stock: inStock,
    price_details,
    ean,
    bundle,
  } = product;

  const lastBreadcrumb = { title, type: 'product', id: 'product' };
  const breadcrumbs = useBreadcrumbsCatalogItems(category_id, lastBreadcrumb);
  const whereOrderProps = useWhereOrderProps(ean);
  const badges = useProductBadges(product);

  const tabsContainerRef = useRef<HTMLDivElement>(null);
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const handleClickToCartButton = () => {
    setActiveTabIndex(0);
    if (tabsContainerRef.current) {
      const headerHeight = document.getElementById('header')?.offsetHeight || 0;
      const offsetContainer = tabsContainerRef.current.getBoundingClientRect().top;
      const scrollValue = window.scrollY;
      const scrollTop = offsetContainer + scrollValue - headerHeight;

      window.scrollTo({ top: scrollTop, behavior: 'smooth' });
    }

    sendAddToCartClick({
      place: 'item_card',
      ean,
    });
  };

  const handleClickTab = (index: number) => {
    setActiveTabIndex(index);
  };

  const tabsProps: ProductPageTabsProps = {
    productContentProps: {
      taxons,
      ingredients,
      nutritionFacts: nutrition_facts,
      description,
    },
    tabsContainerRef,
    inStock,
    activeTabIndex,
    onTabClick: handleClickTab,
    whereOrderProps,
  };

  const mainInfoProps: ProductMainInfoProps = {
    images: gallery,
    title,
    volume,
    weight,
    minPrice: price_details.price_from,
    maxPrice: price_details.price_to,
    unit,
    bundle,
    inStock,
    onButtonClick: handleClickToCartButton,
    badges,
  };

  return {
    tabsProps,
    breadcrumbs,
    mainInfoProps,
  };
};

export default useProductPageProps;
