import useLocalize from "src/services/localize/useLocalize";
import Stack from "src/ui-kit/Stack/Stack";
import { Typography } from "src/ui-kit/Typography";

const EmptyListMessage = () => {
  const localize = useLocalize();

  return (
    <Stack
      dataMarker="EmptyListMessage"
      sbs={{
        p: {
          tablet: '0 32px',
          phone: '0 16px',
        },
      }}
    >
      <Typography variant='text6'>
        {localize('filters.empty')}
      </Typography>
    </Stack>
  );
};

export default EmptyListMessage;
