import useLocalize from "src/services/localize/useLocalize";
import IconFont from "src/ui-kit/IconFont";
import StylishBox from "src/ui-kit/StylishBox/StylishBox";
import { useTheme } from "src/ui-kit/theme";
import { Typography } from "src/ui-kit/Typography";


interface CatalogFiltersMobileArrowHeaderProps {
  onBackClick: () => void;
  onClearClick?: () => void;
  title: string;
  isVisibleClearButton?: boolean;
}

const CatalogFiltersMobileArrowHeader = (props: CatalogFiltersMobileArrowHeaderProps) => {
  const { onBackClick, onClearClick, title, isVisibleClearButton } = props;

  const localize = useLocalize();
  const theme = useTheme();

  return (
    <StylishBox
      sbs={{
        p: '16px 24px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: `${theme.base.border.v1} ${theme.base.color.gray200}`,
      }}
    >
      <StylishBox
        dataMarker="Back Arrow Button"
        element="span"
        elementProps={{
          onClick: onBackClick,
        }}
        sbs={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <IconFont
          name='arrow-left'
          color="gray500"
          size="22px"
          sbs={{ mr: '12px' }}
        />
        <Typography
          element="span"
          variant="h5"
        >
          {title}
        </Typography>
      </StylishBox>
      {isVisibleClearButton && (
        <StylishBox
          dataMarker="Clear Button"
          element="span"
          elementProps={{
            onClick: onClearClick,
          }}
        >
          <Typography variant="text6" element="span" color="gray500">
            {localize('general.clear-all')}
          </Typography>
        </StylishBox>
      )}
    </StylishBox>
  );
};

export default CatalogFiltersMobileArrowHeader;
