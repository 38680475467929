import { tablet } from 'src/styles/media';
import css from 'styled-jsx/css';

const styles = css`
  .FooterSocials__item {
    margin-bottom: 32px;
  }

  @media ${tablet} {
    .FooterSocials__item {
      margin-bottom: 24px;
    }
  }
`;

export default styles;
