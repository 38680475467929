import styled from '@emotion/styled';
import { phone } from 'src/styles/media';
import typography from 'src/styles/typography';
import { StyledThemeProps } from 'src/ui-kit/theme/types';

export const CatalogFiltersMobileCategoriesButtonStyled = styled('button', {
  shouldForwardProp: (prop) => prop !== 'theme',
})<StyledThemeProps>(({ theme }) => `
  background-color: transparent;
  width: 100%;
  position: relative;
  justify-content: space-between;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  border: none;
  border-bottom: ${theme.base.border.v1} ${theme.base.color.gray200};
  outline: none;
  font-size: 14px;
  color: inherit;
  line-height: 24px;

  .CatalogFiltersMobileCategoriesButton__activeName {
    ${typography.text}
    padding-right: 15px;
    padding-left: 15px;
    margin-left: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${theme.base.color.gray600};
  }
  .CatalogFiltersMobileCategoriesButton__title {
    flex-shrink: 0;
  }
  .CatalogFiltersMobileCategoriesButton__icon {
    transform: rotate(-90deg);
    margin-right: 6px;
  }

  @media ${phone} {
    padding: 10px 16px;
  }
`);
