import { Fragment, ReactNode } from 'react';
import StylishBox from 'src/ui-kit/StylishBox/StylishBox';
import { useTheme } from 'src/ui-kit/theme';

interface Props {
  opaqueBackground?: boolean;
  children: ReactNode;
}

export const PageWrap = (props: Props) => {
  const { opaqueBackground, children } = props;
  const theme = useTheme();

  return (
    <Fragment>
      <StylishBox
        sbs={{
          display: {
            desktop: 'flex',
          },
          m: '0 auto',
          width: '100%',
          pb: '72px',
          backgroundColor: opaqueBackground ? theme.base.color.white : 'transparent',
        }}
        className='PageWrap'
      >
        {children}
      </StylishBox>
    </Fragment>
  );
};


export default PageWrap;
