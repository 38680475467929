import cn from 'classnames';
import React, { ReactElement, ReactNode } from 'react';
import { useTheme } from "src/ui-kit/theme";
import { TabsPanelStyled } from "./TabsPanel.styles";

export interface TabProps {
  title: string;
  children: ReactNode;
  dataMarker?: string;
}

export interface TabsPanelProps {
  activeTabIndex: number;
  onTabClick(index: number): void;
  children: ReactElement<TabProps> | ReactElement<TabProps>[];
}

const TabsPanel = (props: TabsPanelProps) => {
  const { children, activeTabIndex = 0, onTabClick } = props;
  const childrenArray = React.Children.toArray(children) as ReactElement<TabProps>[];
  const theme = useTheme();

  return (
    <TabsPanelStyled className="TabsPanel" theme={theme}>
      <div className="TabsPanel__buttons">
        {
          childrenArray.map((tab, index) => {
            const isActive = index === activeTabIndex;
            const {
              dataMarker,
              title,
            } = tab.props;

            const buttonDataMarker = dataMarker ? `${dataMarker} button` : null;
            return (
              <button
                className={cn("TabsPanel__button", {
                  TabsPanel__button_active: isActive,
                })}
                key={`tab button ${index}`}
                onClick={() => { onTabClick(index); }}
                data-marker={buttonDataMarker}
              >
                {title}
              </button>
            );
          })
        }
      </div>

      <div className="TabsPanel__content">
        {childrenArray[activeTabIndex]}
      </div>
    </TabsPanelStyled >
  );
};

export default TabsPanel;

