import styled from '@emotion/styled';
import { tablet } from 'src/styles/media';
import { StyledThemeProps } from 'src/ui-kit/theme/types';

export const ScrollUpButtonStyled = styled('button', {
  shouldForwardProp: (prop) => prop !== 'theme',
})<StyledThemeProps>(({ theme }) => `
    position: fixed;
    right: 32px;
    bottom: 84px;
    z-index: 9;
    padding: 0;
    border: none;
    cursor: pointer;
    background: ${theme.base.color.gray300};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 20px;
    color: ${theme.base.color.gray600};
    transition: 0.2s all ease-in;

  &:hover {
    color:${theme.base.color.dark};
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  }

  @media ${tablet} {
    right: 20px;
    bottom: 122px;
  }
`);
