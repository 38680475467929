import styles from './Slider.styles';
import { IProps } from './types';

const Slider = (props: IProps) => {
  const { sliderRef, children, controls, customNavigation, dataMarker = 'Slider Viewport' } = props;

  return (
    <div
      className='Slider'
      onMouseOver={controls?.handleMouseOver}
      onMouseOut={controls?.handleMouseOut}
    >
      <div
        className='Slider__viewport'
        data-testid='slider_viewport'
        data-marker={dataMarker}
        ref={sliderRef}
        onScroll={controls?.handleScrollViewport}
        onTouchStart={controls?.handleTouchStart}
        onTouchEnd={controls?.handleTouchEnd}
        onTouchMove={controls?.handleTouchMove}
      >
        {children}
      </div>

      {customNavigation && customNavigation}

      <style jsx>{styles}</style>
    </div>
  );
};

export default Slider;
