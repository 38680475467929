import styled from '@emotion/styled';
import { desktop, phone, phone_tablet, tablet } from 'src/styles/media';
import { StyledThemeProps } from 'src/ui-kit/theme/types';

export const CheckListItemStyled = styled('div', {
  shouldForwardProp: (prop) => prop !== 'theme',
})<StyledThemeProps>(({ theme }) => `
  @media ${desktop} {
    &:not(:last-child) {
      padding-bottom: 16px;
    }
  }
  @media ${phone_tablet} {
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${theme.base.color.gray200};

    &.CheckListItem_fullWidth {
      width: 100%;
    }
  }

  @media ${tablet} {
    padding: 16px 24px;
  }

  @media ${phone} {
    padding: 10px 16px;
  }
`);
