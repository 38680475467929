import { UberProductChain } from "src/api/getProductDetailsData";
import { RetailChain, UberCatalogProduct } from "src/api/types";
import useCategories from "src/services/categories/useCategories";
import useLang from "src/services/language/useLang";
import useLocalize from "src/services/localize/useLocalize";
import { findCategoryTitle } from "src/utils/categories/findCategoryTitle";
import { generateProductSchema } from "../helpers/generateProductSchema";
import { getProductPageDescription } from "../helpers/getProductPageDescription";

interface Params {
  chains: RetailChain[];
  retailInfoChains: UberProductChain[];
  product: UberCatalogProduct;
}

export const useProductPageSEO = (params: Params) => {
  const {
    product,
    retailInfoChains,
    chains,
  } = params;

  const localize = useLocalize();
  const categories = useCategories();
  const lang = useLang();

  const {
    title: productTitle,
    price_details: {
      price_from: minPrice,
      price_to: maxPrice,
    },
    category_id,
  } = product;

  const categoryTitle = findCategoryTitle(categories, category_id) || '';
  const title = `${productTitle} - ${localize('products.meta.title')}`;

  const microMarking = generateProductSchema({
    product,
    lang,
    localize,
  });

  const description = getProductPageDescription({
    productTitle,
    categoryTitle,
    minPrice,
    maxPrice,
    retailInfoChains,
    chains,
    localize,
  });

  return {
    title,
    description,
    microMarking,
  };
};
