export function getNextPosition(...args: number[]): number;
export function getNextPosition(
  scrollLeft: number,
  slideWidth: number,
  slidesPerScroll?: number,
): number {
  const currPosition: number = scrollLeft / slideWidth;
  const perfPosition: number = Math.ceil(currPosition);
  const addPosition = slidesPerScroll ? slidesPerScroll - 1 : 0;

  if (perfPosition - currPosition > 0.05) return perfPosition + addPosition;

  return perfPosition + addPosition + 1;
}
