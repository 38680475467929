import React, { Fragment } from 'react';
import styles from './PageWrapAside.styles';
import { FC } from 'react';

const PageWrapAside: FC = ({ children }) => (
  <Fragment>
    <div className='PageWrapAside'>
      {children}
    </div>
    <style jsx>{styles}</style>
  </Fragment>
);


export default PageWrapAside;
