import dynamic from "next/dynamic";
import { Fragment } from "react";
import FlexList from "src/components/FlexList/FlexList";
import { RetailInfo, UnavailableListLabels } from "../../types";
import RetailsUnavailableLabel from "../UnavailableLabel/RetailsUnavailableLabel";

const RetailInfoCard = dynamic(
  () => import('../RetailInfoCard/RetailInfoCard'),
  { ssr: false },
);

interface RetailsInfoUnavailableListProps {
  items: RetailInfo[];
  onRetailInfoCardClick: (isAvailable: boolean, chainId: string) => void;
  onClickOpenAm: () => void;
  size: 'small' | 'medium' | 'large';
  unavailableListLabels: UnavailableListLabels;
}

const RetailsInfoUnavailableList = (props: RetailsInfoUnavailableListProps) => {
  const { onClickOpenAm, onRetailInfoCardClick, items, size, unavailableListLabels} = props;

  return (
    <Fragment>
      <RetailsUnavailableLabel
        onClick={onClickOpenAm}
        label={unavailableListLabels.label}
        buttonLabel={unavailableListLabels.button}
      />

      <div data-marker='Unavailable Retails'>
        <FlexList
          size={size}
          data={items}
          renderItem={(retailInfo) => (
            <RetailInfoCard
              isAvailable={false}
              size={size}
              retailInfo={retailInfo}
              onClick={() => onRetailInfoCardClick(false, retailInfo.id)}
              dataStatus='inactive'
            />
          )}
        />
      </div>
    </Fragment>
  );
};

export default RetailsInfoUnavailableList;
