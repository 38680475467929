import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { CatalogProductBase } from "src/api/types";
import { DEFAULT_PRODUCTS_PER_PAGE } from "src/data/constants";
import useLang from "src/services/language/useLang";
import makeSearchString from "src/utils/system/makeSearchString";
import { CatalogData, LoadMoreAndPaginationParams, LoadMoreAndPaginationProps, PaginationProps } from "./useLoadMoreAndPaginationTypes";

const useLoadMoreAndPagination = <T extends CatalogData>(
  params: LoadMoreAndPaginationParams<T>,
): LoadMoreAndPaginationProps => {
  const {
    products,
    categoryId,
    productsCount,
    sort,
    fetchDataHandler,
    isFuzzy,
  } = params;

  const [loadMoreIsFetching, setLoadMoreIsFetching] = useState(false);
  const [loadMoreProducts, setLoadMoreProducts] = useState<CatalogProductBase[]>([]);
  const language = useLang();
  const {
    query,
    push,
    asPath,
  } = useRouter();
  const queryPage = Number(query?.page) || 1;
  const [activePage, setActivePage] = useState(queryPage);

  const paginationPageCount = !productsCount ? 1 : Math.ceil(productsCount / DEFAULT_PRODUCTS_PER_PAGE);
  const paginationIsVisible = paginationPageCount > 1;
  const loadMoreIsVisible = paginationIsVisible && paginationPageCount > activePage;

  useEffect(() => {
    /**
     * reset data
     * after changing query params
     * to reinitialize load more functionality
     */
    setLoadMoreProducts([]);
    setActivePage(queryPage);
  }, [query, queryPage]);

  const handleLoadMore = async () => {
    setLoadMoreIsFetching(true);
    const newPage = activePage + 1;

    const { firstLevelFilter, ...restQuery } =  query;

    const searchString = makeSearchString(
      {
        ...restQuery,
        page: newPage.toString(),
        category_id: categoryId,
        per_page: `${DEFAULT_PRODUCTS_PER_PAGE}`,
        sort,
      },
    );

    const response = await fetchDataHandler({
      language,
      searchString,
    });

    if (response.status === 'success') {
      setActivePage(newPage);
      setLoadMoreProducts([...loadMoreProducts, ...response.data.results]);
    }

    setLoadMoreIsFetching(false);
  };

  const handlePaginationClick = ({ selected }: { selected: number }) => {
    const path = asPath.split('?')[0];
    const newPage = selected + 1;

    const { page, lang, categoryId, sort, ...restQuery } = query;

    const searchString = makeSearchString({
      ...restQuery,
      sort,
      page: newPage > 1 ? `${newPage}` : undefined,
      // Limitations of OpenSearch:
      // - May struggle with very large datasets, causing performance issues.
      // - Fuzzy search can be resource-intensive, leading to slower query times.
      // - Requires careful tuning of indexing and query parameters to balance accuracy and performance.
      // - Less effective with highly structured or exact-match data needs.
      fuzzy: isFuzzy ? 'true' : undefined,
    });

    push(`${path}${searchString}`);
  };

  const hrefPaginationBuilder = (pageNumber: number) => {
    const path = asPath.split('?')[0];

    return pageNumber > 1 ? `${path}?page=${pageNumber}` : path;
  };

  const paginationProps: PaginationProps = {
    pageCount: paginationPageCount,
    marginPagesDisplayed: 1,
    pageRangeDisplayed: 5,
    forcePage: queryPage - 1,
    disableInitialCallback: true,
    onPageChange: handlePaginationClick,
    hrefBuilder: hrefPaginationBuilder,
  };

  const productsToShow = [...products, ...loadMoreProducts];

  return {
    handleLoadMore,
    paginationIsVisible,
    loadMoreIsVisible,
    loadMoreIsFetching,
    products: productsToShow,
    paginationProps,
  };
};

export default useLoadMoreAndPagination;
