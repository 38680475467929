import { useEffect, useRef, useState } from "react";

interface Position {
  x: number;
  y: number;
}

interface Dimensions {
  width: number;
  height: number;
}

interface ExtendedMouseEvent extends MouseEvent {
  currentTarget: HTMLElement;
}

const useCursorPosition = () => {
  const [position, setPosition] = useState<Position>({
    x: 0,
    y: 0,
  });

  const [dimensions, setDimensions] = useState<Dimensions>({
    width: 0,
    height: 0,
  });

  const [isActive, setIsActive] = useState(false);

  const mouseMoveHandler = (e: ExtendedMouseEvent) => {
    const boundingRect = e.currentTarget.getBoundingClientRect();

    setPosition({
      x: e.offsetX,
      y: e.offsetY,
    });

    setDimensions({
      width: boundingRect.width,
      height: boundingRect.height,
    });
  };

  const mouseOverHandler = () => setIsActive(true);
  const mouseOutHandler = () => setIsActive(false);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const node = ref.current;
    if (node !== null) {
      node.addEventListener('mousemove', mouseMoveHandler);
      node.addEventListener('mouseover', mouseOverHandler);
      node.addEventListener('mouseout', mouseOutHandler);

      return (() => {
        node.removeEventListener('mousemove', mouseMoveHandler);
        node.removeEventListener('mouseover', mouseOverHandler);
        node.removeEventListener('mouseout', mouseOutHandler);
      });
    }
  }, []);

  return {
    ref,
    position,
    dimensions,
    isActive,
  };
};

export default useCursorPosition;
