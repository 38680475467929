import css from 'styled-jsx/css';

const styles = css`
  .ThumbsNavigationControl {
    height: 100%;
    width: 16px;
    background: rgba(239, 239, 239, 0.88);
    border: 0;
    padding: 0;
    position: absolute;
    top: 0;
    cursor: pointer;
    outline: 0;
    font-size: 8px;
  }
  .ThumbsNavigationControl_left {
    left: 0;
  }
  .ThumbsNavigationControl_right {
    right: 0;
  }
`;

export default styles;
