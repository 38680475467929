import { desktop } from 'src/styles/media';
import css from 'styled-jsx/css';

const styles = css`
  .HeaderLogin {
    display: flex;
    align-items: center;
  }

  .HeaderLogin__accountDropdown {
    display: none;
  }

  @media ${desktop} {
    .HeaderLogin__accountDropdown {
      display: block;
    }

    .HeaderLogin__loginButton {
      display: none;
    }

    .HeaderLogin__accountNavigation {
      overflow: hidden;
      width: 264px;
      margin: 0 -24px -12px;
    }
  }
`;

export default styles;
