import { ParsedUrlQuery } from "querystring";
import { RangeFilter } from "src/api/types";
import { FILTER_PRICE_SEPARATOR } from "src/data/constants";
import { RangeNumbers } from "./getPriceAndCurrencyText";

export default function getParsedFilterPriceValue(rawValue: string): RangeNumbers {
  const [rawMin, rawMax] = rawValue.split(FILTER_PRICE_SEPARATOR);

  const min = Number(rawMin) || 0;
  const max = Number(rawMax) || 0;

  return [min, max];
}

export const getMinMaxQueryFilterValue = (filter: RangeFilter, query: ParsedUrlQuery) => {
  const filterPriceValue = Array.isArray(query.price) ? undefined : query.price;
  const min = Math.floor(filter.min / 100) * 100;
  const max = Math.ceil(filter.max / 100) * 100;
  const [minValue, maxValue] = filterPriceValue
    ? getParsedFilterPriceValue(filterPriceValue)
    : [min, max];
  const resultMin = minValue < min ? minValue : min;
  const resultMax = maxValue > max ? maxValue : max;

  return {
    result: {
      min: resultMin,
      max: resultMax,
    },
    values: {
      min: minValue,
      max: maxValue,
    },
  };
};
