import { Fragment, ReactNode } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useTheme } from 'src/ui-kit/theme';
import IconFont from '../../ui-kit/IconFont';

interface Props {
  id: string;
  content: ReactNode;
}

const InfoTooltip = (props: Props) => {
  const { id, content } = props;
  const theme = useTheme();

  return (
    <Fragment>
      <IconFont
        name='info'
        elementProps={{ id }}
        dataMarker='tooltip'
      />
      <ReactTooltip
        anchorSelect={`#${id}`}
        place="top"
        style={{
          backgroundColor: theme.base.color.dark,
          borderRadius: 3,
          opacity: 1,
        }}
      >
        {content}
      </ReactTooltip>
    </Fragment>
  );
};

export default InfoTooltip;
