function pluralize(number: number, pluralFormsString: string) {
  const pluralForms = pluralFormsString.split(',');
  const byHundred = Math.abs(number) % 100;
  const byTen = byHundred % 10;

  if (byHundred > 10 && byHundred < 20) {
    return pluralForms[2];
  }

  if (byTen > 1 && byTen < 5) {
    return pluralForms[1];
  }

  if (byTen === 1) {
    return pluralForms[0];
  }

  return pluralForms[2];
}

export default pluralize;
