import { desktop, phone } from 'src/styles/media';
import css from 'styled-jsx/css';

const styles = css`
  .AppLinks {
    display: flex;
    justify-content: flex-start;
  }
  .AppLinks__link {
    margin-right: 8px;
    transition: 0.2s opacity ease-in-out;
    line-height: 0;
  }
  @media ${desktop} {
    .AppLinks {
      justify-content: flex-start;
    }
    .AppLinks__link {
      margin: 0 12px 0 0;
    }
    .AppLinks {
        pointer-events: none;
      }
    .AppLinks .AppLinks__link {
      pointer-events: all;
    }
    .AppLinks:hover .AppLinks__link {
      opacity: 0.5;
    }
    .AppLinks .AppLinks__link:hover {
      opacity: 1;
    }
    .AppLinks_grayscaleUnhovered:hover .AppLinks__link {
      opacity:.5;
      filter: grayscale(1);
    }
    .AppLinks_grayscaleUnhovered .AppLinks__link:hover {
      opacity: 1;
      filter: grayscale(0);
    }
  }

  @media ${phone} {
    .AppLinks_centerPhone {
      justify-content: center;
    }
    .AppLinks_centerPhone .AppLinks__link {
      margin: 0 4px;
    }
  }
`;

export default styles;
