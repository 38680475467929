import { SwitchLocale, ZsuButton } from 'src/modules/header';
import { useTheme } from 'src/ui-kit/theme';
import replaceTemplateWithCurrentYear from 'src/utils/replaceTemplateWithCurrentYear';
import { useLocalize } from '../../services/localize/useLocalize';
import { Typography } from '../../ui-kit/Typography';
import CenteringComponent from '../CenteringComponent';
import ContactInformation from '../ContactInformation/ContactInformation';
import Logo from '../Logo/Logo';
import Responsive from '../Responsive';
import { FooterStyled } from './Footer.styles';
import FooterMenu from './FooterMenu/FooterMenu';
import FooterSocials from './FooterSocials/FooterSocials';
import FooterUpButton from './FooterUpButton/FooterUpButton';

const Footer = () => {
  const theme = useTheme();
  const localize = useLocalize();

  return (
    <FooterStyled
      theme={theme}
      className="Footer"
    >
      <CenteringComponent>
        <div className="Footer__topLine">
          <div className="Footer__column">
            <div className="Footer__logo">
              <Logo size='large' />
            </div>
            <Responsive displayOn='phoneTablet'>
              <div className='Footer__lang'>
                <SwitchLocale />
              </div>
              <div className='Footer__zsuLink'>
                <ZsuButton />
              </div>
            </Responsive>

            <ContactInformation />
          </div>

          <div className="Footer__column Footer__column_wide">
            <div className='Footer__flexWrap'>
              <FooterMenu />
              <FooterSocials />
            </div>
          </div>
        </div>

        <div className="Footer__bottomLine">
          <Typography
            variant={{general: 'text2', phone: 'text6'}}
            color='gray500'
            dataMarker='Copyright'
          >
            {replaceTemplateWithCurrentYear(localize('copyright_text'), '%year%')}
          </Typography>
          <FooterUpButton />
        </div>

      </CenteringComponent>
    </FooterStyled>
  );
};

export default Footer;
