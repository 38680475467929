import cn from 'classnames';
import { useTheme } from '../theme';
import { SliderFieldStyled } from './SliderField.styles';
import useSliderField from './useSliderField';

export type RangeNumbers = [number, number];

export interface SliderFieldProps {
  value: number | RangeNumbers;
  min?: number;
  max?: number;
  step?: number;
  dataMarker?: string;
  onChange(value: number | RangeNumbers): void;
}

const SliderField = (props: SliderFieldProps) => {
  const { dataMarker } = props;

  const {
    handleMouseDown,
    movingMode,
    rootRef,
    activeTrackPosition,
    thumbsPositions,
  } = useSliderField(props);

  const theme = useTheme();

  return (
    <SliderFieldStyled
      theme={theme}
      className={cn('SliderField', {
        SliderField_movingMode: movingMode,
      })}
      ref={rootRef}
      onMouseDown={handleMouseDown as React.MouseEventHandler<HTMLDivElement>}
      data-testid='SliderField'
      data-marker={dataMarker}
    >
      <div className='SliderField__track' />

      {thumbsPositions.map((pos, i) => (
        <div
          key={i}
          className='SliderField__thumb'
          style={{ left: `${pos}%` }}
          data-testid='SliderField__thumb'
        />
      ))}

      <div
        className='SliderField__track SliderField__track_active'
        style={{
          left: activeTrackPosition.left,
          right: activeTrackPosition.right,
        }}
        data-testid='SliderField__activeTrack'
      />
    </SliderFieldStyled>
  );
};

export default SliderField;
