import css from 'styled-jsx/css';

const styles = css`
  .CartCountIcon {
    position: relative;
    display: inline-block;
  }

  .CartCountIcon__count {
    position: absolute;
    top: -5px;
    right: -2px;
    min-width: 19px;
    padding: 2px 5px;
    transform: translateX(50%);
    background: #FB3F4C;
    border-radius: 8px;
    color: #fff;
    font-size: 13px;
    line-height: 1;
    text-align: center;
  }
`;

export default styles;
