import { ITouchData } from '../types';
import { scrollSlider } from './scrollSlider';
import React, { useCallback, useEffect, useRef, useState } from "react";
import { setSlidesDataStatus } from './setSlidesDataStatus';
import { setBoundaryPosition } from './setBoundaryPosition';
import { getSwipeParams } from './getSwipeParams';
import { getIsVisible } from './getIsVisible';

const touchXData: ITouchData = {
  touchStart: 0,
  timestampStart: 0,
  touchEnd: 0,
  timestampEnd: 0,
  touchCurrent: 0,
};

interface SliderProps {
  fullWidthScroll?: boolean;
  slidesPerScroll?: number;
  autoScrollTimer?: number;
  duration?: number;
  withAutoScroll?: boolean;
  onSwipe?: (value: number) => void;
  onEndPositionLeft?: (value: boolean) => void;
  onEndPositionRight?: (value: boolean) => void;
  endlessLeft?: boolean;
  endlessRight?: boolean;
  withSwiperMode?: boolean;
}

export const useSlider = ({
  fullWidthScroll = false,
  slidesPerScroll = 1,
  autoScrollTimer = 3000,
  duration = 350,
  withAutoScroll = false,
  onSwipe = () => {},
  onEndPositionLeft = () => {},
  onEndPositionRight = () => {},
  endlessLeft = false,
  endlessRight = false,
  withSwiperMode,
}: SliderProps) => {

  const autoScrollIntervalId = useRef<NodeJS.Timer | null>(null);
  const currentScrollLeft = useRef<number | null>(null);
  const viewport = useRef<HTMLDivElement>(null);

  const [isEndPositionRight, setEndPositionRight] = useState(false);
  const [isEndPositionLeft, setEndPositionLeft] = useState(true);
  const [currentPosition, setCurrentPosition] = useState(0);

  const childrenSlidesCount =
    viewport.current && viewport.current.children.length;

  useEffect(() => {
    onEndPositionRight(isEndPositionRight);
  }, [isEndPositionRight, onEndPositionRight]);

  useEffect(() => {
    onEndPositionLeft(isEndPositionLeft);
  }, [isEndPositionLeft, onEndPositionLeft]);

  useEffect(() => {
    if (withSwiperMode && viewport.current) {
      viewport.current.style.overflow = "hidden";
    }
    if (withAutoScroll) {
      launchAutoScroll();
    }

    if (viewport.current) {
      setBoundaryPosition(viewport.current, {
        onBegin: setEndPositionLeft,
        onEnd: setEndPositionRight,
        isBegin: isEndPositionLeft,
        isEnd: isEndPositionRight,
      });
      setSlidesDataStatus(viewport.current);
    }

    return () => {
      if (autoScrollIntervalId.current) {
        clearInterval(autoScrollIntervalId.current);
      }
    };
    /** @todo simplify method */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [withSwiperMode, viewport.current ]);

  useEffect(() => {
    if (viewport.current) {
      setBoundaryPosition(viewport.current, {
        onBegin: setEndPositionLeft,
        onEnd: setEndPositionRight,
        isBegin: isEndPositionLeft,
        isEnd: isEndPositionRight,
      });
      setSlidesDataStatus(viewport.current);
    }
    /** @todo use dependencies */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childrenSlidesCount]);

  useEffect(() => {
    onSwipe(currentPosition);

    if (viewport.current) {
      currentScrollLeft.current = viewport.current?.scrollLeft;
    }
  }, [currentPosition, onSwipe]);

  const handleScrollViewport = useCallback(() => {
    if (viewport.current) {
      setBoundaryPosition(viewport.current, {
        onBegin: setEndPositionLeft,
        onEnd: setEndPositionRight,
        isBegin: isEndPositionLeft,
        isEnd: isEndPositionRight,
      });
      setSlidesDataStatus(viewport.current);
    }
  }, [isEndPositionLeft, isEndPositionRight]);

  function handleNext(): void {
    if (viewport.current) {
      scrollSlider(viewport.current, {
        scrollDirection: "right",
        callback: setCurrentPosition,
        slidesPerScroll,
        fullWidthScroll,
        duration: duration,
        position: isEndPositionRight && endlessRight ? 0 : null,
      });
    }
  }

  function handlePrev(): void {
    if (viewport.current) {
      scrollSlider(viewport.current, {
        scrollDirection: "left",
        callback: setCurrentPosition,
        slidesPerScroll,
        fullWidthScroll,
        duration: duration,
        position:
          isEndPositionLeft && endlessLeft
            ? viewport.current.children.length - 1
            : null,
      });
    }
  }

  const handleTouchStart = useCallback(
    (event: React.TouchEvent<HTMLDivElement>): void => {
      if (autoScrollIntervalId.current) {
        clearInterval(autoScrollIntervalId.current);
      }

      if (!withSwiperMode) {
        return;
      }
      touchXData.touchStart = event.changedTouches[0].clientX;
      touchXData.timestampStart = Date.now();

      currentScrollLeft.current = viewport.current?.scrollLeft || null;
    },
    [withSwiperMode],
  );

  const handleTouchEnd = useCallback(
    (event: React.TouchEvent<HTMLDivElement>): void => {
      if (!withSwiperMode) {
        return;
      }

      touchXData.touchEnd = event.changedTouches[0].clientX;
      touchXData.timestampEnd = Date.now();
      const { swipeDirection, eventType } = getSwipeParams(touchXData);

      let position: number | null = null;
      if (endlessLeft && isEndPositionLeft && viewport.current) {
        position = viewport.current.children.length - 1;
      }

      if (endlessRight && isEndPositionRight) {
        position = 0;
      }

      if (eventType === "swipe" && viewport.current && swipeDirection) {
        scrollSlider(viewport.current, {
          scrollDirection: swipeDirection,
          callback: setCurrentPosition,
          duration: duration,
          position: position,
        });
      }

      if (eventType === "move" && viewport.current && swipeDirection) {
        const nextPosition = getIsVisible(
          viewport.current,
          currentPosition,
          0.3,
        )
          ? currentPosition
          : null;

        scrollSlider(viewport.current, {
          scrollDirection: swipeDirection,
          callback: setCurrentPosition,
          duration: duration,
          position: nextPosition,
        });
      }
    },
    [
      currentPosition,
      duration,
      endlessLeft,
      endlessRight,
      isEndPositionLeft,
      isEndPositionRight,
      withSwiperMode,
    ],
  );

  const handleTouchMove = useCallback(
    (event: React.TouchEvent<HTMLDivElement>): void => {
      if (!withSwiperMode) {
        return;
      }

      touchXData.touchCurrent = event.changedTouches[0].clientX;

      const { touchStart, touchCurrent } = touchXData;
      const spacing = touchStart - touchCurrent;

      if (viewport.current && currentScrollLeft.current) {
        viewport.current.scrollLeft = currentScrollLeft.current + spacing;
      }
    },
    [withSwiperMode],
  );

  function handleMouseOver(): void {
    if (autoScrollIntervalId.current) {
      clearInterval(autoScrollIntervalId.current);
    }
  }

  const launchAutoScroll = useCallback(() => {
    if (
      !withAutoScroll ||
      (viewport.current && viewport.current.children.length <= 1)
    ) {
      return;
    }

    autoScrollIntervalId.current = setInterval(() => {
      if (viewport.current) {
        if (isEndPositionRight) {
          scrollSlider(viewport.current, {
            callback: setCurrentPosition,
            duration: duration,
            position: 0,
          });
        } else {
          scrollSlider(viewport.current, {
            scrollDirection: "right",
            duration: duration,
            callback: setCurrentPosition,
          });
        }
      }

    }, autoScrollTimer);
  }, [autoScrollTimer, duration, isEndPositionRight, withAutoScroll]);

  const handleMouseOut = useCallback((): void => {
    launchAutoScroll();
  }, [launchAutoScroll]);

  function goToSlide(position: number): void {
    if (viewport.current) {
      scrollSlider(viewport.current, {
        callback: setCurrentPosition,
        duration: duration,
        position,
      });
    }
  }

  return {
    sliderRef: viewport,
    controls: {
      onNextSlide: handleNext,
      onPrevSlide: handlePrev,
      onGoToSlide: goToSlide,
      sliderState: {
        currentPosition,
        isEndPositionLeft,
        isEndPositionRight,
      },
    },
    sliderControls: {
      handleTouchStart,
      handleTouchEnd,
      handleTouchMove,
      handleScrollViewport,
      handleMouseOver,
      handleMouseOut,
    },
  };
};
