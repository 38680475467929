import { MouseEvent } from 'react';
import IconZoom from 'src/components/Icons/IconZoom';
import StylishBox from 'src/ui-kit/StylishBox/StylishBox';

interface Props {
  dataMarker?: string;
  onClick(event: MouseEvent): void;
}

const ProductBtnZoomImage = (props: Props) => {
  const { onClick, dataMarker } = props;

  return (
    <StylishBox
      className="ProductBtnZoomImage"
      dataMarker={dataMarker}
      elementProps={{
        onClick,
      }}
      sbs={{
        position: 'absolute',
        left: '0',
        bottom: '0',
        width: '40px',
        height: '40px',
        p: '8px',
        zIndex: '1',
      }}
    >
      <IconZoom />
    </StylishBox>
  );
};

export default ProductBtnZoomImage;
